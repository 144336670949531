import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
} from "../components/FormComponents";
import CenterSelector from "../components/CenterSelector";

const Schema = Yup.object().shape({
    user_name: Yup.string().required(),
    center_name: Yup.string().required(),
    caller_name: Yup.string().required(),
    enquiry_type: Yup.string().required(),
    call_notes: Yup.string(),
    call_actions: Yup.string().required(),
    call_type: Yup.string().required(),
    call_sentiment: Yup.string().required(),
});

const ClassicRegister = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/log-call`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Porsche Call Logging</h1>
            <Break />

            {submitSuccessful ? (
                <>
                    <p>Call successfully submitted</p>
                    <a href="/log-call">Log another?</a>
                </>
            ) : (
                <>
                    <p>Please complete the details in full below</p>
                    <Break />

                    <Formik
                        initialValues={{
                            user_name: "",
                            center_name: "",
                            caller_name: "",
                            enquiry_type: "",
                            call_notes: "",
                            call_actions: "",
                            call_type: "",
                            call_sentiment: "",
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ values, errors, touched, setFieldValue }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="user_name">
                                        Your name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="user_name"
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "user_name",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="Emily Stewart-Wooler">
                                            Emily Stewart-Wooler
                                        </option>
                                        <option value="Hayley Da Costa">
                                            Hayley Da Costa
                                        </option>
                                        <option value="Henry Debenham">
                                            Henry Debenham
                                        </option>
                                        <option value="Jonny Browning">
                                            Jonny Browning
                                        </option>
                                        <option value="Krista Bune">
                                            Krista Bune
                                        </option>
                                        <option value="Paula Hayes">
                                            Paula Hayes
                                        </option>
                                        <option value="Stephanie McQueen">
                                            Stephanie McQueen
                                        </option>
                                    </StyledField>
                                    {errors.user_name && touched.user_name ? (
                                        <Error>User name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="center_name">
                                        Porsche Centre <Required />
                                    </StyledLabel>
                                    <CenterSelector
                                        value={values.center_name}
                                        name="center_name"
                                        onChange={setFieldValue}
                                    />
                                    {errors.center_name &&
                                    touched.center_name ? (
                                        <Error>
                                            Porsche Centre is required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="caller_name">
                                        Caller name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="caller_name"
                                        name="caller_name"
                                    />
                                    {errors.caller_name &&
                                    touched.caller_name ? (
                                        <Error>
                                            Staff contact name is required
                                        </Error>
                                    ) : null}

                                    <h3>Call details</h3>
                                    <Break />

                                    <StyledLabel htmlFor="enquiry_type">
                                        Enquiry Type <Required />
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="enquiry_type"
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "enquiry_type",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="Asset Enquiry">
                                            Asset Enquiry
                                        </option>
                                        <option value="Event Enquiry">
                                            Event Enquiry
                                        </option>
                                        <option value="Change Company Details">
                                            Change Company Details
                                        </option>
                                        <option value="Technical Issue">
                                            Technical Issue
                                        </option>
                                        <option value="Other">Other</option>
                                    </StyledField>
                                    {errors.enquiry_type &&
                                    touched.enquiry_type ? (
                                        <Error>Enquiry Type is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="call_actions">
                                        Call Actions <Required />
                                    </StyledLabel>
                                    <Field
                                        style={{
                                            width: "100%",
                                            border: "1px solid grey",
                                            borderRadius: "3px",
                                            padding: "0.8em",
                                            marginBottom: "1em",
                                            boxSizing: "border-box",
                                        }}
                                        as="textarea"
                                        rows={5}
                                        id="call_actions"
                                        name="call_actions"
                                    />
                                    {errors.call_actions &&
                                    touched.call_actions ? (
                                        <Error>Call Actions is required</Error>
                                    ) : null}
                                    <StyledLabel htmlFor="call_notes">
                                        Call Notes
                                    </StyledLabel>
                                    <Field
                                        style={{
                                            width: "100%",
                                            border: "1px solid grey",
                                            borderRadius: "3px",
                                            padding: "0.8em",
                                            marginBottom: "1em",
                                            boxSizing: "border-box",
                                        }}
                                        as="textarea"
                                        rows={5}
                                        id="call_notes"
                                        name="call_notes"
                                    />
                                    {errors.call_notes && touched.call_notes ? (
                                        <Error>{errors.call_notes}</Error>
                                    ) : null}

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div style={{ flexGrow: 1 }}>
                                            <StyledLabel htmlFor="call_type">
                                                Call Type <Required />
                                            </StyledLabel>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    id="call_type"
                                                    name="call_type"
                                                    value="Incoming"
                                                />
                                                Incoming
                                            </label>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    id="call_type"
                                                    name="call_type"
                                                    value="Outgoing"
                                                />
                                                Outgoing
                                            </label>
                                            {errors.call_type &&
                                            touched.call_type ? (
                                                <Error>
                                                    Call type is required
                                                </Error>
                                            ) : null}
                                        </div>
                                        <div style={{ flexGrow: 1 }}>
                                            <StyledLabel htmlFor="call_sentiment">
                                                Call Sentiment <Required />
                                            </StyledLabel>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    id="call_sentiment"
                                                    name="call_sentiment"
                                                    value="Positive"
                                                />
                                                Positive
                                            </label>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    id="call_sentiment"
                                                    name="call_sentiment"
                                                    value="Neutral"
                                                />
                                                Neutral
                                            </label>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    id="call_sentiment"
                                                    name="call_sentiment"
                                                    value="Negative"
                                                />
                                                Negative
                                            </label>
                                            {errors.call_sentiment &&
                                            touched.call_sentiment ? (
                                                <Error>
                                                    Call Sentiment is required
                                                </Error>
                                            ) : null}
                                        </div>
                                    </div>
                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default ClassicRegister;
