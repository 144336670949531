import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ClassicRegister from "./routes/ClassicRegister";
import ArtworkRequest from "./routes/ArtworkRequest";
import LogCall from "./routes/LogCall";
import Unsubscribe from "./routes/Unsubscribe";
import UnsubscribeForm from "./routes/UnsubscribeForm";
import NetworkTraining from "./routes/NetworkTraining";
import MarketingAwards from "./routes/MarketingAwards";
import DriverSelection from "./routes/DriverSelection";
import ExclusiveManufaktur from "./routes/ExclusiveManufaktur";
import Tequipment from "./routes/Tequipment";
import FeedbackCircle from "./routes/FeedbackCircle";
import DublinInterests from "./routes/DublinInterests";
import NightOfMotorsport from "./routes/NightOfMotorsport";
import TorqueSignup from "./routes/TorqueSignup";
import SheffieldServingSurvey from "./routes/SheffieldServingSurvey";
import TorqueContent from "./routes/TorqueContent";
import PCMC1 from "./routes/PCMC1";
import PCMC2 from "./routes/PCMC2";
import PCMC2Results from "./routes/PCMC2Results";
import PCMC2Single from "./routes/PCMC2Single";
import PCMC2SingleResults from "./routes/PCMC2SingleResults";
import PreTyreTrainingSurvey from "./routes/PreTyreTrainingSurvey";
import PostTyreTrainingSurvey from "./routes/PostTyreTrainingSurvey";

const App = () => {
    if (window.location.hostname === "porschegbprint.co.uk") {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route path="/" component={ArtworkRequest} />
                    </Switch>
                </Router>
            </div>
        );
    } else {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route path="/register" component={ClassicRegister} />
                        <Route path="/log-call" component={LogCall} />
                        <Route path="/unsubscribe" component={Unsubscribe} />
                        <Route
                            path="/unsubscribe-form"
                            component={UnsubscribeForm}
                        />
                        <Route
                            path="/network-training"
                            component={NetworkTraining}
                        />
                        <Route
                            path="/marketing-awards"
                            component={MarketingAwards}
                        />
                        <Route
                            path="/training-event/driver-selection"
                            component={DriverSelection}
                        />
                        <Route
                            path="/training-event/exclusive-manufaktur"
                            component={ExclusiveManufaktur}
                        />
                        <Route
                            path="/training-event/tequipment"
                            component={Tequipment}
                        />
                        <Route
                            path="/feedback-circle"
                            component={FeedbackCircle}
                        />
                        <Route
                            path="/dublin-interests"
                            component={DublinInterests}
                        />
                        <Route
                            path="/night-of-motorsport"
                            component={NightOfMotorsport}
                        />
                        <Route path="/torque-signup" component={TorqueSignup} />
                        <Route
                            path="/torque-content"
                            component={TorqueContent}
                        />
                        <Route path="/pcmc-1" component={PCMC1} />
                        <Route
                            path="/pcmc-2-multi/results"
                            component={PCMC2Results}
                        />
                        <Route path="/pcmc-2-multi" component={PCMC2} />
                        <Route
                            path="/pcmc-2/results"
                            component={PCMC2SingleResults}
                        />
                        <Route path="/pcmc-2" component={PCMC2Single} />
                        <Route
                            path="/sheffield-servicing-survey"
                            component={SheffieldServingSurvey}
                        />

                        <Route
                            path="/pre-tyre-training-survey"
                            component={PreTyreTrainingSurvey}
                        />

                        <Route
                            path="/post-tyre-training-survey"
                            component={PostTyreTrainingSurvey}
                        />
                    </Switch>
                </Router>
            </div>
        );
    }
};

export default App;
