import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledTextarea,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    get_out_of_today: Yup.string().required("Please enter your feedback"),
});

const PCMC2Single = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/management-conference/survey-feedback`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitError("");
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <h1 className="title">Porsche Centre Management Conference 2023</h1>

            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <h2>Thank you</h2>
                        <p></p>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                get_out_of_today: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({
                                errors,
                                touched,
                                setFieldValue,
                                values,
                                ...rest
                            }) => {
                                // console.log({ values });

                                return (
                                    <Form>
                                        <StyledLabel htmlFor="get_out_of_today">
                                            What would you like to get out of
                                            today's sessions?
                                            <Required />
                                        </StyledLabel>
                                        <StyledTextarea
                                            name="get_out_of_today"
                                            rows={5}
                                            placeholder="Please specify"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "get_out_of_today",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        {errors.get_out_of_today &&
                                        touched.get_out_of_today ? (
                                            <Error>
                                                {errors.get_out_of_today}
                                            </Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                {submitting
                                                    ? "Submitting..."
                                                    : "Submit details"}
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default PCMC2Single;
