import React, { useState, useEffect } from "react";
import axios from "axios";

import styled from "styled-components";

import Logo from "../components/Logo";

import { Loader, LogoContainer, Break } from "../components/FormComponents";

const Container = styled.div`
    position: relative;
    max-width: 80vw;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem;
`;

const TagCloud = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 0 auto 50px auto;
`;

const Answer = styled.p`
    font-size: 1.7rem;
    padding: 1em;
    background: #eee;
    border-radius: 5px;
    margin: 0.5em;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    white-space: pre-wrap;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        z-index: 1;
        transform: scale(1.5);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    }
`;

const PCMC2SingleResults = () => {
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [scaledAnswer, setScaledAnswer] = useState<any>();

    //Ten fake answers for now
    const [results, setResults] = useState<any>([
        // {
        //     id: 1,
        //     get_out_of_today: "I want to learn more about the new Taycan",
        // },
        // {
        //     id: 2,
        //     get_out_of_today:
        //         "I want to learn a lot more about the new 911, and the new 911 GT3, as well as all the other 911 variants",
        // },
        // { id: 3, get_out_of_today: "I want to learn more about the new 718" },
        // {
        //     id: 4,
        //     get_out_of_today: "I want to learn more about the new Panamera",
        // },
        // { id: 5, get_out_of_today: "I want to learn more about the new Macan" },
        // {
        //     id: 6,
        //     get_out_of_today: "I want to learn more about the new Cayenne",
        // },
        // { id: 7, get_out_of_today: "How much is a service on a 911?" },
        // {
        //     id: 8,
        //     get_out_of_today: "How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? How much is a service on a 718? ",
        // },
        // { id: 9, get_out_of_today: "How much is a service on a Macan?" },
        // { id: 10, get_out_of_today: "I want to learn more about the new Taycan" },
        // {
        //     id: 11,
        //     get_out_of_today: "I want to learn a lot more about the new 911, and the new 911 GT3, as well as all the other 911 variants",
        // },
        // { id: 12, get_out_of_today: "I want to learn more about the new 718" },
        // { id: 13, get_out_of_today: "I want to learn more about the new Panamera" },
        // { id: 14, get_out_of_today: "I want to learn more about the new Macan" },
        // { id: 15, get_out_of_today: "I want to learn more about the new Cayenne" },
        // { id: 16, get_out_of_today: "How much is a service on a 911?" },
        // { id: 17, get_out_of_today: "How much is a service on a 718?" },
        // { id: 18, get_out_of_today: "How much is a service on a Macan?" },
    ]);

    useEffect(() => {
        //Poll for results every 5 seconds
        const interval = setInterval(() => {
            loadResults();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const loadResults = () => {
        setRefreshing(true);

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/porsche/management-conference/survey-feedback`
            )
            .then(({ data }) => {
                setRefreshing(false);
                setLoading(false);
                setResults(data.data);
            })
            .catch((e) => {
                console.log(e);
                setRefreshing(false);
                setLoading(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>
            <h1 className="title">
                What would you like to get out of today's sessions?
            </h1>
            <Break />
            <TagCloud>
                {results.map(
                    (result: { id: number; get_out_of_today: string }) => {
                        return (
                            <Answer key={result.id}>
                                {result.get_out_of_today}
                            </Answer>
                        );
                    }
                )}
            </TagCloud>{" "}
        </Container>
    );
};

export default PCMC2SingleResults;
