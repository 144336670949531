import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import StyledDropzone from "../components/StyledDropzone";
import Thumbnails from "../components/Thumbnails";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledRadioButton,
    StyledLabel,
    StyledRadioButtonLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
} from "../components/FormComponents";

const FILE_SIZE_MB = 10;
const FILE_SIZE = FILE_SIZE_MB * 1000000; // Megabytes (not Mebibytes)
const SUPPORTED_FORMATS = {
    "image/jpg": [],
    "image/jpeg": [],
    "image/gif": [],
    "image/png": [],
    "image/avif": [],
    "text/plain": [],
    "text/csv": [],
    "application/pdf": [],
    "application/vnd.ms-excel": [],
    "application/msword": [],
    "application/vnd.ms-powerpoint": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
};

const Schema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    contact_number: Yup.string().required("Contact number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    department: Yup.string().required("Department is required"),
    content_title: Yup.string().required("Title is required"),
    comments: Yup.string(),
    file_uploads: Yup.mixed()
        .required("File is required")
        .test(
            "FILE_COUNT_LESS_THAN_ONE",
            "File is required",
            (value) => value && value.length > 0
        )
        .test(
            "FILE_SIZE_TOO_LARGE",
            "Combined File size too large - total limit is " +
                FILE_SIZE_MB +
                " megabytes",
            (value) => {
                let combinedSize = 0;
                if (value.length > 0) {
                    combinedSize = value
                        .map((item) => item.size)
                        .reduce((acc, cur) => acc + cur);
                }
                return value && combinedSize <= FILE_SIZE;
            }
        )
        .test(
            "FILE_FORMAT_NOT_SUPPORTED",
            "Unsupported format found",
            (value, { createError }) => {
                const supportedTypes = Object.keys(SUPPORTED_FORMATS);

                let unsupportedTypes = value
                    .filter((item) => !supportedTypes.includes(item.type))
                    .map((item) => item.type);

                if (unsupportedTypes.length > 0) {
                    let plural = unsupportedTypes.length > 1 ? "s" : "";
                    return createError({
                        message:
                            "Unsupported format" +
                            plural +
                            ": " +
                            unsupportedTypes.join(", "),
                    });
                }

                return unsupportedTypes.length === 0;
            }
        ),
});

const TorqueContent = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const full_name = queryString.get("full_name") || "";
    const contact_number = queryString.get("contact_number") || "";
    const email = queryString.get("email") || "";
    const department = queryString.get("department") || "";
    const content_title = queryString.get("content_title") || "";
    const comments = queryString.get("comments") || "";

    const handleOnSubmit = (values) => {
        setSubmitting(true);

        const formdata = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === "file_uploads") {
                var files = values[key];
                for (let i = 0; i < files.length; i++) {
                    formdata.append("file_uploads[]", files[i]);
                }
            } else {
                formdata.append(key, values[key]);
            }
        });

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/torque-content`,
                formdata,
                { headers: { "Content-Type": "multipart/form-data" } }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <h1 className="title">Porsche Torque Q1 2023</h1>

            <>
                {submitSuccessful ? (
                    <>
                        <p>Thank you for your Porsche Torque submission.</p>
                        <p>
                            If you have any questions, please contact Katherine
                            Roe at katherine.roe@porsche.co.uk
                        </p>
                        <Break />
                    </>
                ) : (
                    <>
                        <p>
                            Welcome to the Porsche Torque content submission
                            area.
                        </p>
                        <p>
                            Please use the form below to submit your content for
                            the Q1 2023 edition of Porsche Torque.
                        </p>
                        <p>
                            If you have any questions, please contact Katherine
                            Roe at katherine.roe@porsche.co.uk
                        </p>
                        <Break />
                        <Formik
                            initialValues={{
                                full_name: full_name,
                                contact_number: contact_number,
                                email: email,
                                department: department,
                                content_title: content_title,
                                comments: comments,
                                file_uploads: [],
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <StyledLabel htmlFor="full_name">
                                            Full name <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="full_name"
                                            name="full_name"
                                        />
                                        {errors.full_name &&
                                        touched.full_name ? (
                                            <Error>{errors.full_name}</Error>
                                        ) : null}
                                        <StyledLabel htmlFor="contact_number">
                                            Contact Number <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="contact_number"
                                            name="contact_number"
                                        />
                                        {errors.contact_number &&
                                        touched.contact_number ? (
                                            <Error>
                                                {errors.contact_number}
                                            </Error>
                                        ) : null}
                                        <StyledLabel htmlFor="email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="email" name="email" />
                                        {errors.email && touched.email ? (
                                            <Error>{errors.email}</Error>
                                        ) : null}
                                        <StyledLabel htmlFor="department">
                                            Department <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="department"
                                            name="department"
                                        />
                                        {errors.department &&
                                        touched.department ? (
                                            <Error>{errors.department}</Error>
                                        ) : null}
                                        <Break />
                                        <StyledLabel htmlFor="content_title">
                                            Content Title <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="content_title"
                                            name="content_title"
                                        />
                                        {errors.content_title &&
                                        touched.content_title ? (
                                            <Error>
                                                {errors.content_title}
                                            </Error>
                                        ) : null}
                                        <StyledLabel htmlFor="comments">
                                            Comments
                                        </StyledLabel>
                                        <StyledField
                                            id="comments"
                                            name="comments"
                                        />
                                        {errors.comments && touched.comments ? (
                                            <Error>{errors.comments}</Error>
                                        ) : null}
                                        <StyledLabel htmlFor="file_uploads">
                                            Files <Required />
                                        </StyledLabel>

                                        <StyledDropzone
                                            onDrop={(acceptedFiles) => {
                                                // do nothing if no files
                                                if (
                                                    acceptedFiles.length === 0
                                                ) {
                                                    return;
                                                }

                                                // on drop we add to the existing files
                                                setFieldValue(
                                                    "file_uploads",
                                                    values.file_uploads.concat(
                                                        acceptedFiles
                                                    )
                                                );
                                            }}
                                            accept={SUPPORTED_FORMATS}
                                            multiple={true}
                                            showClearButton={
                                                values.file_uploads.length > 0
                                            }
                                            onClear={() => {
                                                // on drop we add to the existing files
                                                setFieldValue(
                                                    "file_uploads",
                                                    []
                                                );
                                            }}
                                        >
                                            {values.file_uploads.length ===
                                                0 && (
                                                <p>
                                                    Drag and drop files here, or
                                                    click to select files
                                                </p>
                                            )}
                                            {values.file_uploads.length > 0 && (
                                                <Thumbnails
                                                    files={values.file_uploads}
                                                />
                                            )}
                                        </StyledDropzone>
                                        {errors.file_uploads &&
                                        touched.file_uploads ? (
                                            <Error>{errors.file_uploads}</Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                Submit Content
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required Field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default TorqueContent;
