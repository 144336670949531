import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledRadioButton,
    StyledRadioButtonLabel,
    StyledTextarea,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    explain_tyre_wall_info_1_1: Yup.bool(),
    tyre_wall_learn_more_1_1a: Yup.string().when("explain_tyre_wall_info_1_1", {
        is: false,
        then: Yup.string().required("Please provide an answer"),
    }),
    explain_n_rated_tyre_1_2: Yup.bool().required("Please select an answer"),
    explain_why_n_rated_tyre_1_3: Yup.bool().required(
        "Please select an answer"
    ),
    explain_tyre_materials_1_4: Yup.bool().required("Please select an answer"),
    explain_tyre_construction_1_5: Yup.bool().required(
        "Please select an answer"
    ),
    explain_tread_patterns_1_6: Yup.bool().required("Please select an answer"),
    discuss_winter_tyres_1_7: Yup.bool().required("Please select an answer"),
    explain_run_flats_1_8: Yup.bool().required("Please select an answer"),
    explain_eu_tyre_label_1_9: Yup.bool().required("Please select an answer"),
    explain_electric_tyres_1_10: Yup.bool().required("Please select an answer"),

    helpful_2_1: Yup.bool().required("Please select an answer"),
    content_length_2_2: Yup.bool().required("Please select an answer"),
    content_length_feedback_2_2a: Yup.string().when("content_length_2_2", {
        is: false,
        then: Yup.string().required("Please select an answer"),
    }),
    beats_online_2_3: Yup.bool().required("Please select an answer"),
    beats_online_feedback_2_3a: Yup.string().when("beats_online_2_3", {
        is: false,
        then: Yup.string().required("Please provide an answer"),
    }),
    correct_engagement_2_4: Yup.bool().required("Please select an answer"),
    correct_engagement_feedback_2_4a: Yup.string().when(
        "correct_engagement_2_4",
        {
            is: false,
            then: Yup.string().required("Please provide an answer"),
        }
    ),
    recommend_to_college_2_5: Yup.bool().required("Please select an answer"),
});

const PostTyreTrainingSurvey = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const switchToBoolean = (value) => {
        return value === true || value === "true" || value === "yes";
    };

    const handleOnSubmit = (values) => {
        setSubmitting(true);

        let submisson = values;

        [
            "explain_tyre_wall_info_1_1",
            "explain_n_rated_tyre_1_2",
            "explain_why_n_rated_tyre_1_3",
            "explain_tyre_materials_1_4",
            "explain_tyre_construction_1_5",
            "explain_tread_patterns_1_6",
            "discuss_winter_tyres_1_7",
            "explain_run_flats_1_8",
            "explain_eu_tyre_label_1_9",
            "explain_electric_tyres_1_10",
            "helpful_2_1",
            "content_length_2_2",
            "beats_online_2_3",
            "correct_engagement_2_4",
            "recommend_to_college_2_5",
        ].map((key) => (submisson[key] = switchToBoolean(values[key])));

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/post-tyre-training-survey`,
                submisson
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo />
            </LogoContainer>

            <h1 className="title">Post Tyre Training</h1>

            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <h2>
                            Thank you for taking the time to complete this
                            survey.
                        </h2>
                    </>
                ) : (
                    <>
                        <p>
                            Thank you for attending Tyre Training. So that we
                            can continue to improve this course, please complete
                            the following so we can measure results and
                            feedback.
                        </p>
                        <p>
                            <strong>
                                All answers will be submitted and recorded
                                anonymously.
                            </strong>
                        </p>
                        <p>Duration: 3 minutes</p>

                        <Break style={{ marginTop: "2em" }} />

                        <Formik
                            initialValues={{
                                explain_tyre_wall_info_1_1: null,
                                tyre_wall_learn_more_1_1a: "",
                                explain_n_rated_tyre_1_2: null,
                                explain_why_n_rated_tyre_1_3: null,
                                explain_tyre_materials_1_4: null,
                                explain_tyre_construction_1_5: null,
                                explain_tread_patterns_1_6: null,
                                discuss_winter_tyres_1_7: null,
                                explain_run_flats_1_8: null,
                                explain_eu_tyre_label_1_9: null,
                                explain_electric_tyres_1_10: null,
                                specific_training_feedback_1_11: "",
                                helpful_2_1: null,
                                content_length_2_2: null,
                                content_length_feedback_2_2a: "",
                                beats_online_2_3: null,
                                beats_online_feedback_2_3a: "",
                                correct_engagement_2_4: null,
                                correct_engagement_feedback_2_4a: "",
                                recommend_to_college_2_5: null,
                                missing_content_2_6: "",
                                other_feedback_2_7: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({
                                errors,
                                touched,
                                setFieldValue,
                                values,
                                submitCount,
                            }) => (
                                <Form>
                                    {console.log(values, errors)}
                                    <h2>Section 1 – Tyre knowledge</h2>

                                    {
                                        // 1.	Can you confidently explain to a customer the information included on the side of the tyre wall ie Fitting, load index, OE homologation marking?
                                    }
                                    <StyledLabel htmlFor="explain_tyre_wall_info_1_1">
                                        1. Can you confidently explain to a
                                        customer the information included on the
                                        side of the tyre wall ie Fitting, load
                                        index, OE homologation marking?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_wall_info_1_1"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_wall_info_1_1 ===
                                                    "true" ||
                                                values.explain_tyre_wall_info_1_1 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_wall_info_1_1"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_wall_info_1_1 ===
                                                    "false" ||
                                                values.explain_tyre_wall_info_1_1 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_wall_info_1_1 &&
                                    touched.explain_tyre_wall_info_1_1 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // if explain_tyre_wall_info === "false" show next
                                        // 1A.	Which side wall information specifically would you like to learn more about?
                                    }

                                    {values.explain_tyre_wall_info_1_1 ===
                                        "false" && (
                                        <>
                                            <StyledLabel htmlFor="tyre_wall_learn_more_1_1a">
                                                1A. Which side wall information
                                                specifically would you like to
                                                learn more about?
                                            </StyledLabel>

                                            <StyledTextarea
                                                style={{ marginBottom: "0" }}
                                                id="tyre_wall_learn_more_1_1a"
                                                name="tyre_wall_learn_more_1_1a"
                                                rows={5}
                                                placeholder=""
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "tyre_wall_learn_more_1_1a",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {errors.tyre_wall_learn_more_1_1a &&
                                    touched.tyre_wall_learn_more_1_1a ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // 2.	Can you explain to a customer what the term “N Rated tyre” means?
                                    }

                                    <StyledLabel htmlFor="explain_n_rated_tyre_1_2">
                                        2. Can you explain to a customer what
                                        the term “N Rated tyre” means?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_n_rated_tyre_1_2"
                                            value={"true"}
                                            checked={
                                                values.explain_n_rated_tyre_1_2 ===
                                                    "true" ||
                                                values.explain_n_rated_tyre_1_2 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_n_rated_tyre_1_2"
                                            value={"false"}
                                            checked={
                                                values.explain_n_rated_tyre_1_2 ===
                                                    "false" ||
                                                values.explain_n_rated_tyre_1_2 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_n_rated_tyre_1_2 &&
                                    touched.explain_n_rated_tyre_1_2 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 3.	Can you explain to a customer why N Rated tyres are created for Porsche?
                                    }

                                    <StyledLabel htmlFor="explain_why_n_rated_tyre_1_3">
                                        3. Can you explain to a customer why N
                                        Rated tyres are created for Porsche?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_why_n_rated_tyre_1_3"
                                            value={"true"}
                                            checked={
                                                values.explain_why_n_rated_tyre_1_3 ===
                                                    "true" ||
                                                values.explain_why_n_rated_tyre_1_3 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_why_n_rated_tyre_1_3"
                                            value={"false"}
                                            checked={
                                                values.explain_why_n_rated_tyre_1_3 ===
                                                    "false" ||
                                                values.explain_why_n_rated_tyre_1_3 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_why_n_rated_tyre_1_3 &&
                                    touched.explain_why_n_rated_tyre_1_3 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 4.	Can you explain what materials a tyre is made from?
                                    }

                                    <StyledLabel htmlFor="explain_tyre_materials_1_4">
                                        4. Can you explain what materials a tyre
                                        is made from? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_materials_1_4"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_materials_1_4 ===
                                                    "true" ||
                                                values.explain_tyre_materials_1_4 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_materials_1_4"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_materials_1_4 ===
                                                    "false" ||
                                                values.explain_tyre_materials_1_4 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_materials_1_4 &&
                                    touched.explain_tyre_materials_1_4 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 5.	Can you explain how a tyre is constructed?
                                    }

                                    <StyledLabel htmlFor="explain_tyre_construction_1_5">
                                        5. Can you explain how a tyre is
                                        constructed? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_construction_1_5"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_construction_1_5 ===
                                                    "true" ||
                                                values.explain_tyre_construction_1_5 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_construction_1_5"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_construction_1_5 ===
                                                    "false" ||
                                                values.explain_tyre_construction_1_5 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_construction_1_5 &&
                                    touched.explain_tyre_construction_1_5 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 6.	Do you understand the different tread patterns and can explain the differences to a customer?
                                    }

                                    <StyledLabel htmlFor="explain_tread_patterns_1_6">
                                        6. Do you understand the different tread
                                        patterns and can explain the differences
                                        to a customer? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tread_patterns_1_6"
                                            value={"true"}
                                            checked={
                                                values.explain_tread_patterns_1_6 ===
                                                    "true" ||
                                                values.explain_tread_patterns_1_6 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tread_patterns_1_6"
                                            value={"false"}
                                            checked={
                                                values.explain_tread_patterns_1_6 ===
                                                    "false" ||
                                                values.explain_tread_patterns_1_6 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tread_patterns_1_6 &&
                                    touched.explain_tread_patterns_1_6 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 7.	Can you confidently identify and discuss when a customer requires winter tyres?
                                    }

                                    <StyledLabel htmlFor="discuss_winter_tyres_1_7">
                                        7. Can you confidently identify and
                                        discuss when a customer requires winter
                                        tyres? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="discuss_winter_tyres_1_7"
                                            value={"true"}
                                            checked={
                                                values.discuss_winter_tyres_1_7 ===
                                                    "true" ||
                                                values.discuss_winter_tyres_1_7 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="discuss_winter_tyres_1_7"
                                            value={"false"}
                                            checked={
                                                values.discuss_winter_tyres_1_7 ===
                                                    "false" ||
                                                values.discuss_winter_tyres_1_7 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.discuss_winter_tyres_1_7 &&
                                    touched.discuss_winter_tyres_1_7 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 8.	Can you explain to a customer the difference between run flats and regular tyres?
                                    }

                                    <StyledLabel htmlFor="explain_run_flats_1_8">
                                        8. Can you explain to a customer the
                                        difference between run flats and regular
                                        tyres? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_run_flats_1_8"
                                            value={"true"}
                                            checked={
                                                values.explain_run_flats_1_8 ===
                                                    "true" ||
                                                values.explain_run_flats_1_8 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_run_flats_1_8"
                                            value={"false"}
                                            checked={
                                                values.explain_run_flats_1_8 ===
                                                    "false" ||
                                                values.explain_run_flats_1_8 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_run_flats_1_8 &&
                                    touched.explain_run_flats_1_8 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 9.	Do you understand and can explain an EU tyre label to a customer?
                                    }

                                    <StyledLabel htmlFor="explain_eu_tyre_label_1_9">
                                        9. Do you understand and can explain an
                                        EU tyre label to a customer?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_eu_tyre_label_1_9"
                                            value={"true"}
                                            checked={
                                                values.explain_eu_tyre_label_1_9 ===
                                                    "true" ||
                                                values.explain_eu_tyre_label_1_9 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_eu_tyre_label_1_9"
                                            value={"false"}
                                            checked={
                                                values.explain_eu_tyre_label_1_9 ===
                                                    "false" ||
                                                values.explain_eu_tyre_label_1_9 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_eu_tyre_label_1_9 &&
                                    touched.explain_eu_tyre_label_1_9 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 10.	Can you explain to a customer the benefit of tyres designed specifically for electric models?
                                    }

                                    <StyledLabel htmlFor="explain_electric_tyres_1_10">
                                        10. Can you explain to a customer the
                                        benefit of tyres designed specifically
                                        for electric models? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_electric_tyres_1_10"
                                            value={"true"}
                                            checked={
                                                values.explain_electric_tyres_1_10 ===
                                                    "true" ||
                                                values.explain_electric_tyres_1_10 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_electric_tyres_1_10"
                                            value={"false"}
                                            checked={
                                                values.explain_electric_tyres_1_10 ===
                                                    "false" ||
                                                values.explain_electric_tyres_1_10 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_electric_tyres_1_10 &&
                                    touched.explain_electric_tyres_1_10 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 11.	If you would like specific training included within this course which hasn’t been mentioned, please provide feedback in the box below.
                                    }

                                    <StyledLabel htmlFor="specific_training_feedback_1_11">
                                        11. If you would like specific training
                                        included within this course which hasn't
                                        been mentioned, please provide feedback
                                        in the box below.
                                    </StyledLabel>

                                    <StyledTextarea
                                        style={{ marginBottom: "0" }}
                                        id="specific_training_feedback_1_11"
                                        name="specific_training_feedback_1_11"
                                        rows={5}
                                        placeholder=""
                                        onChange={(e) => {
                                            setFieldValue(
                                                "specific_training_feedback_1_11",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.specific_training_feedback_1_11 &&
                                    touched.specific_training_feedback_1_11 ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // END OF SECTION 1
                                    }

                                    <Break style={{ marginTop: "2em" }} />

                                    <h2>Section 2 – Course feedback</h2>

                                    {
                                        // 'helpful_2_1' => 2.1.	Did you find the course helpful?
                                    }

                                    <StyledLabel htmlFor="helpful_2_1">
                                        1. Did you find the course helpful?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="helpful_2_1"
                                            value={"true"}
                                            checked={
                                                values.helpful_2_1 === "true" ||
                                                values.helpful_2_1 === true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="helpful_2_1"
                                            value={"false"}
                                            checked={
                                                values.helpful_2_1 ===
                                                    "false" ||
                                                values.helpful_2_1 === false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.helpful_2_1 &&
                                    touched.helpful_2_1 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 'content_length_2_2' => 2.2.	Did the length of course feel appropriate to the content?
                                    }

                                    <StyledLabel htmlFor="content_length_2_2">
                                        2. Did the length of course feel
                                        appropriate to the content? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="content_length_2_2"
                                            value={"true"}
                                            checked={
                                                values.content_length_2_2 ===
                                                    "true" ||
                                                values.content_length_2_2 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="content_length_2_2"
                                            value={"false"}
                                            checked={
                                                values.content_length_2_2 ===
                                                    "false" ||
                                                values.content_length_2_2 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.content_length_2_2 &&
                                    touched.content_length_2_2 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 'content_length_feedback_2_2a' => 2.2a. Too long or too short?
                                    }

                                    {values.content_length_2_2 === "false" && (
                                        <>
                                            <StyledRadioButtonLabel>
                                                <StyledRadioButton
                                                    type="radio"
                                                    name="content_length_feedback_2_2a"
                                                    value={"Too long"}
                                                    checked={
                                                        values.content_length_feedback_2_2a ===
                                                        "Too long"
                                                    }
                                                />
                                                Too long
                                            </StyledRadioButtonLabel>
                                            <StyledRadioButtonLabel>
                                                <StyledRadioButton
                                                    type="radio"
                                                    name="content_length_feedback_2_2a"
                                                    value={"Too short"}
                                                    checked={
                                                        values.content_length_feedback_2_2a ===
                                                        "Too short"
                                                    }
                                                />
                                                Too short
                                            </StyledRadioButtonLabel>

                                            {errors.content_length_feedback_2_2a &&
                                            touched.content_length_feedback_2_2a ? (
                                                <Error>
                                                    Please provide an answer
                                                </Error>
                                            ) : null}
                                        </>
                                    )}

                                    {
                                        // 'beats_online_2_3' => 2.3.	Did the course provider a higher level of training vs completing training online?
                                    }

                                    <StyledLabel htmlFor="beats_online_2_3">
                                        3. Did the course provide a higher level
                                        of training vs completing training
                                        online? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="beats_online_2_3"
                                            value={"true"}
                                            checked={
                                                values.beats_online_2_3 ===
                                                    "true" ||
                                                values.beats_online_2_3 === true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="beats_online_2_3"
                                            value={"false"}
                                            checked={
                                                values.beats_online_2_3 ===
                                                    "false" ||
                                                values.beats_online_2_3 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.beats_online_2_3 &&
                                    touched.beats_online_2_3 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 'beats_online_feedback_2_3a' => 2.3a.	Please provide feedback.
                                    }

                                    {values.beats_online_2_3 === "false" && (
                                        <>
                                            <StyledLabel htmlFor="beats_online_feedback_2_3a">
                                                3A. Please provide feedback.
                                            </StyledLabel>

                                            <StyledTextarea
                                                style={{ marginBottom: "0" }}
                                                id="beats_online_feedback_2_3a"
                                                name="beats_online_feedback_2_3a"
                                                rows={5}
                                                placeholder=""
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "beats_online_feedback_2_3a",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {errors.beats_online_feedback_2_3a &&
                                    touched.beats_online_feedback_2_3a ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // 'correct_engagement_2_4' => 2.4.	Did the course have the correct amount of engagement?
                                    }

                                    <StyledLabel htmlFor="correct_engagement_2_4">
                                        4. Did the course have the correct
                                        amount of engagement? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="correct_engagement_2_4"
                                            value={"true"}
                                            checked={
                                                values.correct_engagement_2_4 ===
                                                    "true" ||
                                                values.correct_engagement_2_4 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="correct_engagement_2_4"
                                            value={"false"}
                                            checked={
                                                values.correct_engagement_2_4 ===
                                                    "false" ||
                                                values.correct_engagement_2_4 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.correct_engagement_2_4 &&
                                    touched.correct_engagement_2_4 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 'correct_engagement_feedback_2_4a' => 2.4a.	Please provide feedback.
                                    }

                                    {values.correct_engagement_2_4 ===
                                        "false" && (
                                        <>
                                            <StyledLabel htmlFor="beats_online_feedback_2_3a">
                                                4A. Please provide feedback.
                                            </StyledLabel>

                                            <StyledTextarea
                                                style={{ marginBottom: "0" }}
                                                id="correct_engagement_feedback_2_4a"
                                                name="correct_engagement_feedback_2_4a"
                                                rows={5}
                                                placeholder=""
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "correct_engagement_feedback_2_4a",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {errors.correct_engagement_feedback_2_4a &&
                                    touched.correct_engagement_feedback_2_4a ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // 'recommend_to_college_2_5' => 2.5.	Would you recommend this course to a colleague?
                                    }

                                    <StyledLabel htmlFor="recommend_to_college_2_5">
                                        5. Would you recommend this course to a
                                        colleague? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="recommend_to_college_2_5"
                                            value={"true"}
                                            checked={
                                                values.recommend_to_college_2_5 ===
                                                    "true" ||
                                                values.recommend_to_college_2_5 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="recommend_to_college_2_5"
                                            value={"false"}
                                            checked={
                                                values.recommend_to_college_2_5 ===
                                                    "false" ||
                                                values.recommend_to_college_2_5 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.recommend_to_college_2_5 &&
                                    touched.recommend_to_college_2_5 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 'missing_content_2_6' => 2.6.	Is there any content missing from this course that would be useful to include?
                                    }

                                    <StyledLabel htmlFor="missing_content_2_6">
                                        6. Is there any content missing from
                                        this course that would be useful to
                                        include?
                                    </StyledLabel>

                                    <StyledTextarea
                                        style={{ marginBottom: "0" }}
                                        id="missing_content_2_6"
                                        name="missing_content_2_6"
                                        rows={5}
                                        placeholder=""
                                        onChange={(e) => {
                                            setFieldValue(
                                                "missing_content_2_6",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.missing_content_2_6 &&
                                    touched.missing_content_2_6 ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // 'other_feedback_2_7' => 2.7.	Do you have any other feedback from this course?
                                    }

                                    <StyledLabel htmlFor="other_feedback_2_7">
                                        7. Do you have any other feedback from
                                        this course?
                                    </StyledLabel>

                                    <StyledTextarea
                                        style={{ marginBottom: "0" }}
                                        id="other_feedback_2_7"
                                        name="other_feedback_2_7"
                                        rows={5}
                                        placeholder=""
                                        onChange={(e) => {
                                            setFieldValue(
                                                "other_feedback_2_7",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.other_feedback_2_7 &&
                                    touched.other_feedback_2_7 ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // END OF FORM
                                    }

                                    {((submitCount > 0 &&
                                        Object.keys(errors).length > 0) ||
                                        submitError) && (
                                        <Error>
                                            <p>
                                                There are some errors in your
                                                submission. Please review the
                                                form and correct any highlighted
                                                fields before resubmitting.
                                            </p>
                                        </Error>
                                    )}

                                    <SubmitSection>
                                        <SubmitButton
                                            hasArrow
                                            color="red"
                                            type="submit"
                                            disabled={submitting}
                                        >
                                            Submit details
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>

                                    <div style={{ paddingBottom: "2rem" }}>
                                        <Required /> Required field
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default PostTyreTrainingSurvey;
