import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    RadioContainer,
    CustomRadio,
    RadioCheck,
    RadioContainerOuter,
} from "../components/FormComponents";


const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string().email().required("Email is required"),
    bp_number: Yup.string(),
    interest: Yup.string().required("Interest is required"),
    privacy_policy: Yup.boolean().required("Privacy Policy is required"),
});

const DublinInterests = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [otherInterest, setOtherInterest] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const title = queryString.get("title")?.toLowerCase() || "";
    const first_name = queryString.get("first_name") || "";
    const surname = queryString.get("surname") || "";
    const email = queryString.get("email") || "";
    const bp_number = queryString.get("bp_number") || "";
    const interest = queryString.get("interest") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/dublin-interests`,
                {
                    ...values,
                    interest: values.interest === "other" ? otherInterest : values.interest
                },
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Dublin Interests</h1>
            {/*<Break />*/}

            {submitSuccessful ? (
                <>
                    <p>Dublin Interests successfully submitted</p>
                </>
            ) : (
                <>
                    {/*<p>*/}
                    {/*</p>*/}
                    {/*<Break />*/}

                    <Formik
                        initialValues={{
                            title: title,
                            first_name: first_name,
                            surname: surname,
                            email: email,
                            bp_number: bp_number,
                            interest: interest,
                            privacy_policy: undefined,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >

                        {({ errors, touched, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <h3>Customer details</h3>
                                    <Break />

                                    <StyledLabel htmlFor="title">
                                        Title <Required />
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="title"
                                        value={values.title}
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "title",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="mr">Mr</option>
                                        <option value="mrs">Mrs</option>
                                        <option value="miss">Miss</option>
                                        <option value="ms">Ms</option>
                                    </StyledField>
                                    {errors.title && touched.title ? (
                                        <Error>
                                            {errors.title}
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="first_name">
                                        First name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="first_name"
                                        name="first_name"
                                    />
                                    {errors.first_name && touched.first_name ? (
                                        <Error>
                                            {errors.first_name}
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="surname">
                                        Surname <Required />
                                    </StyledLabel>
                                    <StyledField id="surname" name="surname" />
                                    {errors.surname && touched.surname ? (
                                        <Error>
                                            {errors.surname}
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>
                                            {errors.email}
                                        </Error>
                                    ) : null}

                                    <StyledLabel
                                        style={{ marginTop: "1rem" }}
                                    >
                                        Select your interest:
                                    </StyledLabel>

                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "golf"
                                                    );
                                                }}
                                                checked={values.interest === "golf"}
                                                // @ts-ignore
                                                value="golf"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Golf
                                        </RadioContainer>
                                    </RadioContainerOuter>
                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "motorsport"
                                                    );
                                                }}
                                                checked={values.interest === "motorsport"}
                                                // @ts-ignore
                                                value="motorsport"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Motorsport
                                        </RadioContainer>
                                    </RadioContainerOuter>
                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "sailing"
                                                    );
                                                }}
                                                checked={values.interest === "sailing"}
                                                // @ts-ignore
                                                value="sailing"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Sailing
                                        </RadioContainer>
                                    </RadioContainerOuter>
                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "equestrian"
                                                    );
                                                }}
                                                checked={values.interest === "equestrian"}
                                                // @ts-ignore
                                                value="equestrian"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Equestrian
                                        </RadioContainer>
                                    </RadioContainerOuter>
                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "rugby_soccer_gaa"
                                                    );
                                                }}
                                                checked={values.interest === "rugby_soccer_gaa"}
                                                // @ts-ignore
                                                value="rugby_soccer_gaa"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Rugby/Soccer/GAA
                                        </RadioContainer>
                                    </RadioContainerOuter>
                                    <RadioContainerOuter style={{ marginTop: "1rem" }}>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "interest",
                                                        "other"
                                                    );
                                                }}
                                                checked={values.interest === "other"}
                                                // @ts-ignore
                                                value="other"
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            Other
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    {values.interest === "other" &&
                                    <>
                                        <StyledLabel htmlFor="other_interest">
                                            Please Specify:
                                        </StyledLabel>
                                        <StyledField
                                            value={otherInterest}
                                            onChange={(value: any) => setOtherInterest(value.target.value)}
                                            id="other_interest"
                                            name="other_interest"
                                        />
                                    </>
                                    }

                                    {errors.interest && touched.interest ? (
                                        <Error>
                                            {errors.interest}
                                        </Error>
                                    ) : null}

                                    <p style={{ marginTop: '3rem' }}>Please read the full Privacy Policy</p>

                                    <RadioContainerOuter>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "privacy_policy",
                                                        value.target
                                                            .checked
                                                    );
                                                }}
                                                checked={
                                                    values.privacy_policy
                                                }
                                                // @ts-ignore
                                                value={
                                                    values.privacy_policy
                                                }
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            I agree with the{" "}
                                            <a
                                                style={{ color: 'black' }}
                                                href="https://www.porsche.com/uk/privacy-policy/"
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </a>
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    {errors.privacy_policy &&
                                    touched.privacy_policy ? (
                                        <Error>
                                            {errors.privacy_policy}
                                        </Error>
                                    ) : null}


                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default DublinInterests;
