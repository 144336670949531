import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
} from "../components/FormComponents";
import CenterSelector from "../components/CenterSelector";

const Schema = Yup.object().shape({
    user_name: Yup.string().required("Full name is required"),
    job_role: Yup.string().required("Job role is required"),
    email: Yup.string().required("Email is required"),
    centre_name: Yup.string().required("Porsche Centre is required"),
});

const TorqueSignup = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const user_name = queryString.get("user_name") || "";
    const job_role = queryString.get("job_role") || "";
    const email = queryString.get("email") || "";
    const centre_name = queryString.get("centre_name") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/torque-signup`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <h1 className="title">Torque Sign-Up</h1>
            <p>Please complete the below form to receive an email notification once a new edition of Torque is uploaded to PPN.</p>
            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <p>Torque sign-up successfully submitted</p>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                user_name: user_name,
                                email: email,
                                centre_name: centre_name,
                                job_role: job_role,
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <StyledLabel htmlFor="first_name">
                                            Full name <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="user_name"
                                            name="user_name"
                                        />
                                        {errors.user_name &&
                                        touched.user_name ? (
                                            <Error>{errors.user_name}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="job_role">
                                            Job Role <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="job_role"
                                            name="job_role"
                                        />
                                        {errors.job_role && touched.job_role ? (
                                            <Error>{errors.job_role}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="email" name="email" />
                                        {errors.email && touched.email ? (
                                            <Error>{errors.email}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="centre_name">
                                            Porsche Centre <Required />
                                        </StyledLabel>
                                        <CenterSelector
                                            value={values.centre_name}
                                            name="centre_name"
                                            onChange={setFieldValue}
                                        />
                                        {errors.centre_name &&
                                        touched.centre_name ? (
                                            <Error>{errors.centre_name}</Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                Submit Details
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required Field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default TorqueSignup;
