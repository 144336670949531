import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ThumbnailsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
`;

const ThumbnailsItem = styled.div`
    position: relative;
    background: ${({ background }) => background};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    height: 150px;
    width: 100%;
    color: #ffffff;
    padding: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    word-break: break-all;
    word-wrap: break-word;
`;

const Thumbnails = ({ files }) => {
    return (
        <ThumbnailsContainer>
            {files.map((file, i) => (
                <Thumb key={i} file={file} />
            ))}
        </ThumbnailsContainer>
    );
};

const Thumb = ({ file }) => {
    const [imageSrc, setImageSrc] = useState("");
    const type = file.type.split("/")[0];

    useEffect(() => {
        if (type === "image") {
            let reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(
                    `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${reader.result})`
                );
            };
            reader.readAsDataURL(file);
        } else {
            setImageSrc("#888888");
        }
    }, []);

    return (
        <ThumbnailsItem background={imageSrc}>
            <p>{file.name}</p>
        </ThumbnailsItem>
    );
};

export default Thumbnails;
