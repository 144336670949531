import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledRadioButton,
    StyledRadioButtonLabel,
    StyledTextarea,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    explain_tyre_wall_info_1: Yup.bool(),
    tyre_wall_learn_more_1a: Yup.string().when("explain_tyre_wall_info_1", {
        is: false,
        then: Yup.string().required("Please provide an answer"),
    }),
    explain_n_rated_tyre_2: Yup.bool().required("Please select an answer"),
    explain_why_n_rated_tyre_3: Yup.bool().required("Please select an answer"),
    explain_tyre_materials_4: Yup.bool().required("Please select an answer"),
    explain_tyre_construction_5: Yup.bool().required("Please select an answer"),
    explain_tread_patterns_6: Yup.bool().required("Please select an answer"),
    discuss_winter_tyres_7: Yup.bool().required("Please select an answer"),
    explain_run_flats_8: Yup.bool().required("Please select an answer"),
    explain_eu_tyre_label_9: Yup.bool().required("Please select an answer"),
    explain_electric_tyres_10: Yup.bool().required("Please select an answer"),
});

const PreTyreTrainingSurvey = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const switchToBoolean = (value) => {
        return value === "true" || value === "yes";
    };

    const handleOnSubmit = (values) => {
        setSubmitting(true);

        let submisson = values;

        [
            "explain_tyre_wall_info_1",
            "explain_n_rated_tyre_2",
            "explain_why_n_rated_tyre_3",
            "explain_tyre_materials_4",
            "explain_tyre_construction_5",
            "explain_tread_patterns_6",
            "discuss_winter_tyres_7",
            "explain_run_flats_8",
            "explain_eu_tyre_label_9",
            "explain_electric_tyres_10",
        ].map((key) => (submisson[key] = switchToBoolean(values[key])));

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/pre-tyre-training-survey`,
                submisson
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo />
            </LogoContainer>

            <h1 className="title">Tyre Training</h1>

            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <h2>
                            Thank you for completing this survey. We hope you
                            enjoy and find the training useful.
                        </h2>
                    </>
                ) : (
                    <>
                        <p>
                            Please answer the following questions in advance of
                            your training, so we can ensure that all
                            requirements are factored into course content.
                        </p>
                        <p>
                            <strong>
                                All answers will be submitted and recorded
                                anonymously.
                            </strong>
                        </p>
                        <p>Duration: 3 minutes</p>

                        <Break style={{ marginTop: "2em" }} />

                        <Formik
                            initialValues={{
                                explain_tyre_wall_info_1: null,
                                tyre_wall_learn_more_1a: "",
                                explain_n_rated_tyre_2: null,
                                explain_why_n_rated_tyre_3: null,
                                explain_tyre_materials_4: null,
                                explain_tyre_construction_5: null,
                                explain_tread_patterns_6: null,
                                discuss_winter_tyres_7: null,
                                explain_run_flats_8: null,
                                explain_eu_tyre_label_9: null,
                                explain_electric_tyres_10: null,
                                specific_training_feedback_11: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({
                                errors,
                                touched,
                                setFieldValue,
                                values,
                                submitCount,
                            }) => (
                                <Form>
                                    {
                                        // 1.	Can you confidently explain to a customer the information included on the side of the tyre wall ie Fitting, load index, OE homologation marking?
                                    }
                                    <StyledLabel htmlFor="explain_tyre_wall_info_1">
                                        1. Can you confidently explain to a
                                        customer the information included on the
                                        side of the tyre wall ie Fitting, load
                                        index, OE homologation marking?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_wall_info_1"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_wall_info_1 ===
                                                    "true" ||
                                                values.explain_tyre_wall_info_1 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_wall_info_1"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_wall_info_1 ===
                                                    "false" ||
                                                values.explain_tyre_wall_info_1 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_wall_info_1 &&
                                    touched.explain_tyre_wall_info_1 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // if explain_tyre_wall_info === "false" show next
                                        // 1A.	Which side wall information specifically would you like to learn more about?
                                    }

                                    {values.explain_tyre_wall_info_1 ===
                                        "false" && (
                                        <>
                                            <StyledLabel htmlFor="tyre_wall_learn_more_1a">
                                                1A. Which side wall information
                                                specifically would you like to
                                                learn more about?
                                            </StyledLabel>

                                            <StyledTextarea
                                                id="tyre_wall_learn_more_1a"
                                                name="tyre_wall_learn_more_1a"
                                                rows={5}
                                                placeholder=""
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "tyre_wall_learn_more_1a",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {errors.tyre_wall_learn_more_1a &&
                                    touched.tyre_wall_learn_more_1a ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // 2.	Can you explain to a customer what the term “N Rated tyre” means?
                                    }

                                    <StyledLabel htmlFor="explain_n_rated_tyre_2">
                                        2. Can you explain to a customer what
                                        the term “N Rated tyre” means?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_n_rated_tyre_2"
                                            value={"true"}
                                            checked={
                                                values.explain_n_rated_tyre_2 ===
                                                    "true" ||
                                                values.explain_n_rated_tyre_2 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_n_rated_tyre_2"
                                            value={"false"}
                                            checked={
                                                values.explain_n_rated_tyre_2 ===
                                                    "false" ||
                                                values.explain_n_rated_tyre_2 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_n_rated_tyre_2 &&
                                    touched.explain_n_rated_tyre_2 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 3.	Can you explain to a customer why N Rated tyres are created for Porsche?
                                    }

                                    <StyledLabel htmlFor="explain_why_n_rated_tyre_3">
                                        3. Can you explain to a customer why N
                                        Rated tyres are created for Porsche?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_why_n_rated_tyre_3"
                                            value={"true"}
                                            checked={
                                                values.explain_why_n_rated_tyre_3 ===
                                                    "true" ||
                                                values.explain_why_n_rated_tyre_3 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_why_n_rated_tyre_3"
                                            value={"false"}
                                            checked={
                                                values.explain_why_n_rated_tyre_3 ===
                                                    "false" ||
                                                values.explain_why_n_rated_tyre_3 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_why_n_rated_tyre_3 &&
                                    touched.explain_why_n_rated_tyre_3 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 4.	Can you explain what materials a tyre is made from?
                                    }

                                    <StyledLabel htmlFor="explain_tyre_materials_4">
                                        4. Can you explain what materials a tyre
                                        is made from? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_materials_4"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_materials_4 ===
                                                    "true" ||
                                                values.explain_tyre_materials_4 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_materials_4"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_materials_4 ===
                                                    "false" ||
                                                values.explain_tyre_materials_4 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_materials_4 &&
                                    touched.explain_tyre_materials_4 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 5.	Can you explain how a tyre is constructed?
                                    }

                                    <StyledLabel htmlFor="explain_tyre_construction_5">
                                        5. Can you explain how a tyre is
                                        constructed? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_construction_5"
                                            value={"true"}
                                            checked={
                                                values.explain_tyre_construction_5 ===
                                                    "true" ||
                                                values.explain_tyre_construction_5 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tyre_construction_5"
                                            value={"false"}
                                            checked={
                                                values.explain_tyre_construction_5 ===
                                                    "false" ||
                                                values.explain_tyre_construction_5 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tyre_construction_5 &&
                                    touched.explain_tyre_construction_5 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 6.	Do you understand the different tread patterns and can explain the differences to a customer?
                                    }

                                    <StyledLabel htmlFor="explain_tread_patterns_6">
                                        6. Do you understand the different tread
                                        patterns and can explain the differences
                                        to a customer? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tread_patterns_6"
                                            value={"true"}
                                            checked={
                                                values.explain_tread_patterns_6 ===
                                                    "true" ||
                                                values.explain_tread_patterns_6 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_tread_patterns_6"
                                            value={"false"}
                                            checked={
                                                values.explain_tread_patterns_6 ===
                                                    "false" ||
                                                values.explain_tread_patterns_6 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_tread_patterns_6 &&
                                    touched.explain_tread_patterns_6 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 7.	Can you confidently identify and discuss when a customer requires winter tyres?
                                    }

                                    <StyledLabel htmlFor="discuss_winter_tyres_7">
                                        7. Can you confidently identify and
                                        discuss when a customer requires winter
                                        tyres? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="discuss_winter_tyres_7"
                                            value={"true"}
                                            checked={
                                                values.discuss_winter_tyres_7 ===
                                                    "true" ||
                                                values.discuss_winter_tyres_7 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="discuss_winter_tyres_7"
                                            value={"false"}
                                            checked={
                                                values.discuss_winter_tyres_7 ===
                                                    "false" ||
                                                values.discuss_winter_tyres_7 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.discuss_winter_tyres_7 &&
                                    touched.discuss_winter_tyres_7 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 8.	Can you explain to a customer the difference between run flats and regular tyres?
                                    }

                                    <StyledLabel htmlFor="explain_run_flats_8">
                                        8. Can you explain to a customer the
                                        difference between run flats and regular
                                        tyres? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_run_flats_8"
                                            value={"true"}
                                            checked={
                                                values.explain_run_flats_8 ===
                                                    "true" ||
                                                values.explain_run_flats_8 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_run_flats_8"
                                            value={"false"}
                                            checked={
                                                values.explain_run_flats_8 ===
                                                    "false" ||
                                                values.explain_run_flats_8 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_run_flats_8 &&
                                    touched.explain_run_flats_8 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 9.	Do you understand and can explain an EU tyre label to a customer?
                                    }

                                    <StyledLabel htmlFor="explain_eu_tyre_label_9">
                                        9. Do you understand and can explain an
                                        EU tyre label to a customer?{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_eu_tyre_label_9"
                                            value={"true"}
                                            checked={
                                                values.explain_eu_tyre_label_9 ===
                                                    "true" ||
                                                values.explain_eu_tyre_label_9 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_eu_tyre_label_9"
                                            value={"false"}
                                            checked={
                                                values.explain_eu_tyre_label_9 ===
                                                    "false" ||
                                                values.explain_eu_tyre_label_9 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_eu_tyre_label_9 &&
                                    touched.explain_eu_tyre_label_9 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 10.	Can you explain to a customer the benefit of tyres designed specifically for electric models?
                                    }

                                    <StyledLabel htmlFor="explain_electric_tyres_10">
                                        10. Can you explain to a customer the
                                        benefit of tyres designed specifically
                                        for electric models? <Required />
                                    </StyledLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_electric_tyres_10"
                                            value={"true"}
                                            checked={
                                                values.explain_electric_tyres_10 ===
                                                    "true" ||
                                                values.explain_electric_tyres_10 ===
                                                    true
                                            }
                                        />
                                        Yes
                                    </StyledRadioButtonLabel>
                                    <StyledRadioButtonLabel>
                                        <StyledRadioButton
                                            type="radio"
                                            name="explain_electric_tyres_10"
                                            value={"false"}
                                            checked={
                                                values.explain_electric_tyres_10 ===
                                                    "false" ||
                                                values.explain_electric_tyres_10 ===
                                                    false
                                            }
                                        />
                                        No
                                    </StyledRadioButtonLabel>

                                    {errors.explain_electric_tyres_10 &&
                                    touched.explain_electric_tyres_10 ? (
                                        <Error>Please select an answer</Error>
                                    ) : null}

                                    {
                                        // 11.	If you would like specific training included within this course which hasn’t been mentioned, please provide feedback in the box below.
                                    }

                                    <StyledLabel htmlFor="specific_training_feedback_11">
                                        11. If you would like specific training
                                        included within this course which hasn't
                                        been mentioned, please provide feedback
                                        in the box below.
                                    </StyledLabel>

                                    <StyledTextarea
                                        id="specific_training_feedback_11"
                                        name="specific_training_feedback_11"
                                        rows={5}
                                        placeholder=""
                                        onChange={(e) => {
                                            setFieldValue(
                                                "specific_training_feedback_11",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.specific_training_feedback_11 &&
                                    touched.specific_training_feedback_11 ? (
                                        <Error>Please provide an answer</Error>
                                    ) : null}

                                    {
                                        // END OF FORM
                                    }

                                    {((submitCount > 0 &&
                                        Object.keys(errors).length > 0) ||
                                        submitError) && (
                                        <Error>
                                            <p>
                                                There are some errors in your
                                                submission. Please review the
                                                form and correct any highlighted
                                                fields before resubmitting.
                                            </p>
                                        </Error>
                                    )}

                                    <SubmitSection>
                                        <SubmitButton
                                            hasArrow
                                            color="red"
                                            type="submit"
                                            disabled={submitting}
                                        >
                                            Submit details
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>

                                    <div style={{ paddingBottom: "2rem" }}>
                                        <Required /> Required field
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default PreTyreTrainingSurvey;
