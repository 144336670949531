import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    FlexBox,
    Required,
    LogoContainer,
} from "../components/FormComponents";

import CenterSelector from "../components/CenterSelector";

const Schema = Yup.object().shape({
    center_name: Yup.string().required(),
    center_contact_name: Yup.string().required(),
    title: Yup.string().required(),
    first_name: Yup.string().required(),
    surname: Yup.string().required(),
    address_line_one: Yup.string().required(),
    address_line_two: Yup.string(),
    town: Yup.string().required(),
    county: Yup.string().required(),
    post_code: Yup.string().required(),
    telephone: Yup.string().required(),
    email: Yup.string().email().required(),
    bp_number: Yup.string().required(),
    vehicle_make: Yup.string().required(),
    vin_number: Yup.string().required(),
    vehicle_registration_number: Yup.string().required(),
    consent_period_of_time: Yup.string().required(),
    confirmed_membership: Yup.string().required(),
});

const ClassicRegister = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/customer-classic-details`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Porsche Classic Register</h1>
            <Break />

            {submitSuccessful ? (
                <>
                    <p>Classic register successfully submitted</p>
                </>
            ) : (
                <>
                    <p>
                        Please complete the details in full below to register
                        your customer. If you have any queries regarding the
                        Porsche Classic Register, please contact the PCMS team
                        on 01202 597 333.
                    </p>
                    <Break />

                    <Formik
                        initialValues={{
                            center_name: "",
                            center_contact_name: "",
                            title: "",
                            first_name: "",
                            surname: "",
                            address_line_one: "",
                            address_line_two: "",
                            town: "",
                            county: "",
                            post_code: "",
                            telephone: "",
                            email: "",
                            bp_number: "",
                            vehicle_make: "",
                            vin_number: "",
                            vehicle_registration_number: "",
                            consent_period_of_time: "",
                            confirmed_membership: "",
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ errors, touched, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="center_name">
                                        Your Porsche Centre <Required />
                                    </StyledLabel>

                                    <CenterSelector
                                        value={values.center_name}
                                        name="center_name"
                                        onChange={setFieldValue}
                                    />
                                    {errors.center_name &&
                                    touched.center_name ? (
                                        <Error>
                                            Porsche Centre is required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="center_contact_name">
                                        Staff contact name at Porsche Centre{" "}
                                        <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="center_contact_name"
                                        name="center_contact_name"
                                    />
                                    {errors.center_contact_name &&
                                    touched.center_contact_name ? (
                                        <Error>
                                            Staff contact name is required
                                        </Error>
                                    ) : null}

                                    <h3>Customer details</h3>
                                    <Break />

                                    <StyledLabel htmlFor="title">
                                        Title <Required />
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="title"
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "title",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="mr">Mr</option>
                                        <option value="mrs">Mrs</option>
                                        <option value="miss">Miss</option>
                                        <option value="ms">Ms</option>
                                    </StyledField>
                                    {errors.title && touched.title ? (
                                        <Error>Title is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="first_name">
                                        First name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="first_name"
                                        name="first_name"
                                    />
                                    {errors.first_name && touched.first_name ? (
                                        <Error>First name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="surname">
                                        Surname <Required />
                                    </StyledLabel>
                                    <StyledField id="surname" name="surname" />
                                    {errors.surname && touched.surname ? (
                                        <Error>Surname is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="address_line_one">
                                        Address Line 1 <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="address_line_one"
                                        name="address_line_one"
                                    />
                                    {errors.address_line_one &&
                                    touched.address_line_one ? (
                                        <Error>
                                            Address line 1 is required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="address_line_two">
                                        Address Line 2
                                    </StyledLabel>
                                    <StyledField
                                        id="address_line_two"
                                        name="address_line_two"
                                    />

                                    <StyledLabel htmlFor="town">
                                        Town <Required />
                                    </StyledLabel>
                                    <StyledField id="town" name="town" />
                                    {errors.town && touched.town ? (
                                        <Error>Town is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="county">
                                        County <Required />
                                    </StyledLabel>
                                    <StyledField id="county" name="county" />
                                    {errors.county && touched.county ? (
                                        <Error>County is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="post_code">
                                        Post Code <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="post_code"
                                        name="post_code"
                                    />
                                    {errors.post_code && touched.post_code ? (
                                        <Error>Postcode is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="telephone">
                                        Telephone <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="telephone"
                                        name="telephone"
                                    />
                                    {errors.telephone && touched.telephone ? (
                                        <Error>Telephone is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>
                                            Email is required and must be valid
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="bp_number">
                                        BP Number of customer (must be created
                                        if not on C@P) <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="bp_number"
                                        name="bp_number"
                                    />
                                    {errors.bp_number && touched.bp_number ? (
                                        <Error>BP Number is required</Error>
                                    ) : null}

                                    <h3>Vehicle Details</h3>
                                    <Break />

                                    <StyledLabel htmlFor="vehicle_make">
                                        Vehicle Model <Required />
                                    </StyledLabel>
                                    <StyledField
                                        as="select"
                                        name="vehicle_make"
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "vehicle_make",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="356">356</option>
                                        <option value="911_F">911 F</option>
                                        <option value="911_G">911 G</option>
                                        <option value="911_(964)">
                                            911 (964)
                                        </option>
                                        <option value="911_(993)">
                                            911 (993)
                                        </option>
                                        <option value="911_(996)">
                                            911 (996)
                                        </option>
                                        <option value="911_(997)">
                                            911 (997)
                                        </option>
                                        <option value="912">912</option>
                                        <option value="914">914</option>
                                        <option value="924">924</option>
                                        <option value="928">928</option>
                                        <option value="944">944</option>
                                        <option value="968">968</option>
                                        <option value="986_boxster">
                                            986 Boxster
                                        </option>
                                        <option value="987_boxster">
                                            987 Boxster
                                        </option>
                                        <option value="cayman_987">
                                            Cayman (987)
                                        </option>
                                        <option value="cayenne_e1">
                                            Cayenne E1
                                        </option>
                                    </StyledField>
                                    {errors.vehicle_make &&
                                    touched.vehicle_make ? (
                                        <Error>Vehicle model is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="vehicle_registration_number">
                                        Vehicle Registration Number <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="vehicle_registration_number"
                                        name="vehicle_registration_number"
                                    />
                                    {errors.vehicle_registration_number &&
                                    touched.vehicle_registration_number ? (
                                        <Error>
                                            Vehicle Registration Number is
                                            required
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="vin_number">
                                        VIN Number <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="vin_number"
                                        name="vin_number"
                                    />
                                    {errors.vin_number && touched.vin_number ? (
                                        <Error>VIN Number is required</Error>
                                    ) : null}

                                    <FlexBox>
                                        <Field
                                            type="checkbox"
                                            id="confirmed_membership"
                                            name="confirmed_membership"
                                            style={{ marginRight: "1em" }}
                                        />
                                        <StyledLabel htmlFor="confirmed_membership">
                                            I confirm the customer has completed
                                            the Porsche Classic Register
                                            membership application form{" "}
                                            <Required />
                                        </StyledLabel>
                                    </FlexBox>
                                    {errors.confirmed_membership &&
                                    touched.confirmed_membership ? (
                                        <Error>
                                            Confirm membership is required
                                        </Error>
                                    ) : null}

                                    <FlexBox>
                                        <Field
                                            type="checkbox"
                                            id="consent_period_of_time"
                                            name="consent_period_of_time"
                                            style={{ marginRight: "1em" }}
                                        />
                                        <StyledLabel htmlFor="consent_period_of_time">
                                            I confirm the customer has consented
                                            to be signed up to the Porsche
                                            Classic Register for a period of two
                                            years <Required />
                                        </StyledLabel>
                                    </FlexBox>
                                    {errors.consent_period_of_time &&
                                    touched.consent_period_of_time ? (
                                        <Error>
                                            Confirm consent period of time is
                                            required
                                        </Error>
                                    ) : null}

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default ClassicRegister;
