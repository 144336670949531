import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import axios from "axios";

import {
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
} from "../components/FormComponents";

import Logo from "../components/Logo";
import CenterSelector from "../components/CenterSelector";

const Schema = Yup.object().shape({
    center_name: Yup.string().required(),
    exc_title: Yup.string().required(),
    exc_first_name: Yup.string().required(),
    exc_surname: Yup.string().required(),
    exc_job_role: Yup.string().required(),
    exc_email: Yup.string().required(),
    pds_title: Yup.string().required(),
    pds_first_name: Yup.string().required(),
    pds_surname: Yup.string().required(),
    pds_job_role: Yup.string().required(),
    pds_email: Yup.string().required(),
    teq_title: Yup.string().required(),
    teq_first_name: Yup.string().required(),
    teq_surname: Yup.string().required(),
    teq_job_role: Yup.string().required(),
    teq_email: Yup.string().required(),
});

const Banner = styled.div`
    height: 65vh;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("/porscheclub.jpeg") no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-top: 1em;
    box-sizing: border-box;
    color: white;
`;

const NetworkTrainingContainer = styled.div`
    display: flex;
    padding: 4em;
    max-width: 1000px;
    margin: 0 auto;
    
    @media (max-width: 800px) {
        display: block;
    }
`;

const Content = styled.div`
    width: 40%;
    margin-right: 3em;
    
    p, b {
        margin-bottom: 2em;
    }
    
    @media (max-width: 800px) {
        width: 100%;
        margin-right: 0;
    }
`;

const FormContainer = styled.div`
    flex-grow: 1;
`;

const ThreeColumns = styled.div`
    display: flex;
    
    @media (max-width: 700px) {
        display: block;
    }   
`;

const ThreeColumnSection = styled.div`
    margin-right: 1em;
    
    @media (max-width: 700px) {
        margin-right: 0;
    }   
`;

const Footer = styled.div`
    background: #191f23;
    color: white;
    padding: 2em;
`;

const NetworkTraining = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/network-training`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <>
            <LogoContainer style={{ marginBottom: '1em' }}>
                <Logo style={{ width: '100px' }}/>
            </LogoContainer>
            <Banner>
                <h1>Porsche Centre Training</h1>
                <b>The Belfry Hotel, Sutton Coldfield</b>
                <p style={{ marginTop: '0' }}>11-13th October 2021</p>
            </Banner>
            <NetworkTrainingContainer>
                <Content>
                    <b>How to enter</b>
                    <p>The Porsche Centre Network Training takes place 11-13th October 2021 at the award-winning Belfry Hotel and Resort, Sutton Coldfield.</p>
                    <p>There will be a full day’s training on each of the following:</p>
                    <ul>
                        <li>Exclusive Manufaktur</li>
                        <li>Porsche Driver’s Selection</li>
                        <li>Tequipment</li>
                    </ul>
                    <p>You will need to nominate a different delegate from your Centre to attend on each day.</p>
                    <p>Each Porsche Centre will need to pay £150 per day towards B&B accommodation (paid on departure from venue).</p>
                    <p>Delegates are also invited to a special dinner the night before each training day (so delegates will need to arrive the evening before their scheduled training day).</p>
                    <b>Please register your chosen delegates here, before the 4th of August:</b>
                </Content>

                <FormContainer>
                    {submitSuccessful ? (
                        <>
                            <p>Network training successfully submitted</p>
                        </>
                    ) : (
                    <Formik
                            initialValues={{
                                center_name: "",
                                exc_title: "",
                                exc_first_name: "",
                                exc_surname: "",
                                exc_job_role: "",
                                exc_email: "",
                                pds_title: "",
                                pds_first_name: "",
                                pds_surname: "",
                                pds_job_role: "",
                                pds_email: "",
                                teq_title: "",
                                teq_first_name: "",
                                teq_surname: "",
                                teq_job_role: "",
                                teq_email: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <h3>Choose your centre</h3>
                                        <Break />
                                        <StyledLabel htmlFor="center_name">
                                            Choose your centre <Required />
                                        </StyledLabel>
                                        <CenterSelector
                                            value={values.center_name}
                                            name="center_name"
                                            onChange={setFieldValue}
                                        />
                                        {errors.center_name &&
                                        touched.center_name ? (
                                            <Error>
                                                Porsche Centre is required
                                            </Error>
                                        ) : null}

                                        <h3>Exclusive Manufaktur Delegate</h3>
                                        <Break />

                                        <ThreeColumns>
                                            <ThreeColumnSection>
                                                <StyledLabel htmlFor="exc_title">
                                                    Title <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    style={{ padding: '0.7em' }}
                                                    as="select"
                                                    name="exc_title"
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            "exc_title",
                                                            value.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    <option value="mr">Mr</option>
                                                    <option value="mrs">Mrs</option>
                                                    <option value="miss">Miss</option>
                                                    <option value="ms">Ms</option>
                                                </StyledField>
                                                {errors.exc_title && touched.exc_title ? (
                                                    <Error>Title is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>
                                            <ThreeColumnSection style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="exc_first_name">
                                                    First name <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    id="exc_first_name"
                                                    name="exc_first_name"
                                                />
                                                {errors.exc_first_name && touched.exc_first_name ? (
                                                    <Error>First name is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>
                                            <div style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="exc_surname">
                                                    Surname <Required />
                                                </StyledLabel>
                                                <StyledField id="exc_surname" name="exc_surname" />
                                                {errors.exc_surname && touched.exc_surname ? (
                                                    <Error>Surname is required</Error>
                                                ) : null}
                                            </div>
                                        </ThreeColumns>

                                        <StyledLabel htmlFor="exc_job_role">
                                            Job Role <Required />
                                        </StyledLabel>
                                        <StyledField id="exc_job_role" name="exc_job_role" />
                                        {errors.exc_job_role && touched.exc_job_role ? (
                                            <Error>
                                                Job Role is required and must be valid
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="exc_email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="exc_email" name="exc_email" />
                                        {errors.exc_email && touched.exc_email ? (
                                            <Error>
                                                Email is required and must be valid
                                            </Error>
                                        ) : null}

                                        <h3>Porsche Driver’s Selection Delegate</h3>
                                        <Break />

                                        <ThreeColumns>
                                            <ThreeColumnSection>
                                                <StyledLabel htmlFor="pds_title">
                                                    Title <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    style={{ padding: '0.7em' }}
                                                    as="select"
                                                    name="pds_title"
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            "pds_title",
                                                            value.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    <option value="mr">Mr</option>
                                                    <option value="mrs">Mrs</option>
                                                    <option value="miss">Miss</option>
                                                    <option value="ms">Ms</option>
                                                </StyledField>
                                                {errors.pds_title && touched.pds_title ? (
                                                    <Error>Title is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>

                                            <ThreeColumnSection style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="pds_first_name">
                                                    First name <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    id="pds_first_name"
                                                    name="pds_first_name"
                                                />
                                                {errors.pds_first_name && touched.pds_first_name ? (
                                                    <Error>First name is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>

                                            <div style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="pds_surname">
                                                    Surname <Required />
                                                </StyledLabel>
                                                <StyledField id="pds_surname" name="pds_surname" />
                                                {errors.pds_surname && touched.pds_surname ? (
                                                    <Error>Surname is required</Error>
                                                ) : null}
                                            </div>
                                        </ThreeColumns>

                                        <StyledLabel htmlFor="pds_job_role">
                                            Job Role <Required />
                                        </StyledLabel>
                                        <StyledField id="pds_job_role" name="pds_job_role" />
                                        {errors.pds_job_role && touched.pds_job_role ? (
                                            <Error>
                                                Job Role is required and must be valid
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="pds_email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="pds_email" name="pds_email" />
                                        {errors.pds_email && touched.pds_email ? (
                                            <Error>
                                                Email is required and must be valid
                                            </Error>
                                        ) : null}

                                        <h3>Tequipment Delegate</h3>
                                        <Break />

                                        <ThreeColumns>
                                            <ThreeColumnSection>
                                                <StyledLabel htmlFor="teq_title">
                                                    Title <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    style={{ padding: '0.7em' }}
                                                    as="select"
                                                    name="teq_title"
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            "teq_title",
                                                            value.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    <option value="mr">Mr</option>
                                                    <option value="mrs">Mrs</option>
                                                    <option value="miss">Miss</option>
                                                    <option value="ms">Ms</option>
                                                </StyledField>
                                                {errors.teq_title && touched.teq_title ? (
                                                    <Error>Title is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>
                                            <ThreeColumnSection style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="teq_first_name">
                                                    First name <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    id="teq_first_name"
                                                    name="teq_first_name"
                                                />
                                                {errors.teq_first_name && touched.teq_first_name ? (
                                                    <Error>First name is required</Error>
                                                ) : null}
                                            </ThreeColumnSection>
                                            <div style={{ flexGrow: 1 }}>
                                                <StyledLabel htmlFor="teq_surname">
                                                    Surname <Required />
                                                </StyledLabel>
                                                <StyledField id="teq_surname" name="teq_surname" />
                                                {errors.teq_surname && touched.teq_surname ? (
                                                    <Error>Surname is required</Error>
                                                ) : null}
                                            </div>
                                        </ThreeColumns>

                                        <StyledLabel htmlFor="teq_job_role">
                                            Job Role <Required />
                                        </StyledLabel>
                                        <StyledField id="teq_job_role" name="teq_job_role" />
                                        {errors.teq_job_role && touched.teq_job_role ? (
                                            <Error>
                                                Job Role is required and must be valid
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="teq_email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="teq_email" name="teq_email" />
                                        {errors.teq_email && touched.teq_email ? (
                                            <Error>
                                                Email is required and must be valid
                                            </Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton type="submit">
                                                Submit
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </FormContainer>
            </NetworkTrainingContainer>
            <Footer>
                &copy; 2021 All rights reserved to Porsche Cars Great Britain Limited and its licensors. Privacy Policy | Legal Notice
            </Footer>
        </>
    );
};

export default NetworkTraining;
