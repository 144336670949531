import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    FlexBox,
    Required,
    LogoContainer,
    RadioContainer,
    CustomRadio,
    RadioCheck,
    RadioContainerOuter,
} from "../components/FormComponents";
import CenterSelector from "../components/CenterSelector";

const Schema = Yup.object().shape({
    center_name: Yup.string().required("Porsche Center is required"),
    name: Yup.string().required("Name is required"),
    job_title: Yup.string().required("Job Title is required"),
    email: Yup.string().required("Email is required and must be valid"),
    questions: Yup.string(),
    attending: Yup.boolean().required("Attending is required"),
    attending_dinner: Yup.boolean(),
    needing_room: Yup.boolean(),
});

const Tepuipment = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const center_name = queryString.get("center_name") || "";
    const name = queryString.get("name") || "";
    const email = queryString.get("email") || "";
    const questions = queryString.get("questions") || "";
    const job_title = queryString.get("job_title") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/training-event/tequipment`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Tequipment Training Event - Tuesday 12th October</h1>
            <Break />

            {submitSuccessful ? (
                <>
                    <p>Tequipment Training Event successfully submitted.</p>
                </>
            ) : (
                <>
                    <p>
                        Please complete the below form and confirm your attendance to the Tequipment Training Event on Tuesday 12th October.
                    </p>

                    <br />

                    <Formik
                        initialValues={{
                            center_name : center_name,
                            name : name,
                            job_title : job_title,
                            email : email,
                            questions : questions,
                            attending : false,
                            attending_dinner : false,
                            needing_room : false,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >

                        {({ errors, touched, setFieldValue, values }) => {
                            return (
                                <Form>
                                    <StyledLabel htmlFor="center_name">
                                        Your Porsche Centre <Required />
                                    </StyledLabel>
                                    <CenterSelector
                                        value={values.center_name}
                                        name="center_name"
                                        onChange={setFieldValue}
                                    />
                                    {errors.center_name &&
                                    touched.center_name ? (
                                        <Error>
                                            {errors.center_name}
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="name">
                                        Name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="name"
                                        name="name"
                                    />
                                    {errors.name && touched.name ? (
                                        <Error>{errors.name}</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="job_title">
                                        Job Title <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="job_title"
                                        name="job_title"
                                    />
                                    {errors.job_title && touched.job_title ? (
                                        <Error>{errors.job_title}</Error>
                                    ) : null}


                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>
                                            {errors.email}
                                        </Error>
                                    ) : null}

                                    <StyledLabel htmlFor="call_actions">
                                        Any questions you would like to be answered at the event
                                    </StyledLabel>
                                    <Field
                                        style={{
                                            width: "100%",
                                            border: "1px solid black",
                                            padding: "1.2em",
                                            marginBottom: "1.2em",
                                            boxSizing: "border-box",
                                        }}
                                        as="textarea"
                                        rows={5}
                                        id="questions"
                                        name="questions"
                                    />

                                    <RadioContainerOuter>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "attending",
                                                        value.target
                                                            .checked
                                                    );
                                                }}
                                                checked={
                                                    values.attending
                                                }
                                                // @ts-ignore
                                                value={
                                                    values.attending
                                                }
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            I confirm I will be attending the Tequipment Training Event on Tuesday 12th October{" "}
                                            <Required />
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    <RadioContainerOuter>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "attending_dinner",
                                                        value.target
                                                            .checked
                                                    );
                                                }}
                                                checked={
                                                    values.attending_dinner
                                                }
                                                // @ts-ignore
                                                value={
                                                    values.attending_dinner
                                                }
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            I confirm that I will be attending the welcome dinner the night before the scheduled training day on Monday 11th October
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    <RadioContainerOuter>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "needing_room",
                                                        value.target
                                                            .checked
                                                    );
                                                }}
                                                checked={
                                                    values.needing_room
                                                }
                                                // @ts-ignore
                                                value={
                                                    values.needing_room
                                                }
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            I confirm that I will need a room on Monday 11th October
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default Tepuipment;
