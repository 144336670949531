import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    RadioContainer,
    CustomRadio,
    RadioCheck,
    RadioContainerOuter,
} from "../components/FormComponents";


const Schema = Yup.object().shape({
    title: Yup.string().required(),
    first_name: Yup.string().required(),
    surname: Yup.string().required(),
    email: Yup.string().required(),
    bp_number: Yup.string(),
    privacy_policy: Yup.boolean().required(),
});

const FeedbackCircle = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const title = queryString.get("title")?.toLowerCase() || "";
    const first_name = queryString.get("first_name") || "";
    const surname = queryString.get("surname") || "";
    const email = queryString.get("email") || "";
    const bp_number = queryString.get("bp_number") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/feedback-circle`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Join the Porsche Feedback Circle</h1>

            {submitSuccessful ? (
                <>
                    <p>Porsche Feedback Circle successfully submitted</p>
                </>
            ) : (
                <>
                    <p>
                        Sign up today using the form below to confirm you are happy to take part in our Porsche Feedback Circle. We will then invite you, on occasion, to give us your thoughts on Porsche related subjects via online surveys.
                    </p>

                    <Formik
                        initialValues={{
                            title: title,
                            first_name: first_name,
                            surname: surname,
                            email: email,
                            bp_number: bp_number,
                            privacy_policy: false,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >

                        {({ errors, touched, setFieldValue, values }) => {
                            return (
                                <Form>

                                    <h3>Personal Details</h3>

                                    <StyledLabel htmlFor="title">
                                        Title <Required />
                                    </StyledLabel>
                                    <StyledField
                                        value={values.title}
                                        as="select"
                                        name="title"
                                        onChange={(value: any) =>
                                            setFieldValue(
                                                "title",
                                                value.target.value
                                            )
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="mr">Mr</option>
                                        <option value="mrs">Mrs</option>
                                        <option value="miss">Miss</option>
                                        <option value="ms">Ms</option>
                                        <option value="master">Master</option>
                                        <option value="mx">Mx</option>
                                        <option value="sir">Sir</option>
                                        <option value="dr">Dr</option>
                                        <option value="lord">Lord</option>
                                        <option value="lady">Lady</option>
                                    </StyledField>
                                    {errors.title && touched.title ? (
                                        <Error>Title is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="first_name">
                                        First name <Required />
                                    </StyledLabel>
                                    <StyledField
                                        id="first_name"
                                        name="first_name"
                                    />
                                    {errors.first_name && touched.first_name ? (
                                        <Error>First name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="surname">
                                        Surname <Required />
                                    </StyledLabel>
                                    <StyledField id="surname" name="surname" />
                                    {errors.surname && touched.surname ? (
                                        <Error>Surname is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField id="email" name="email" />
                                    {errors.email && touched.email ? (
                                        <Error>
                                            Email is required and must be valid
                                        </Error>
                                    ) : null}

                                    <RadioContainerOuter>
                                        <RadioContainer>
                                            <CustomRadio
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "privacy_policy",
                                                        value.target
                                                            .checked
                                                    );
                                                }}
                                                checked={
                                                    values.privacy_policy
                                                }
                                                // @ts-ignore
                                                value={
                                                    values.privacy_policy
                                                }
                                                type="checkbox"
                                            />
                                            <RadioCheck
                                                square={true}
                                                className="checkmark"
                                            />
                                            I agree with the{" "}
                                            <a
                                                style={{ color: 'black' }}
                                                href="https://www.porsche.com/uk/privacy-policy/"
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </a>
                                        </RadioContainer>
                                    </RadioContainerOuter>

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton
                                            hasArrow
                                            color="red"
                                            type="submit"
                                        >
                                            Submit Details
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                    <Required /> Required Field
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default FeedbackCircle;
