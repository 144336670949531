import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    RadioContainer,
    CustomRadio,
    RadioCheck,
    RadioContainerOuter,
} from "../components/FormComponents";
import styled from "styled-components";

const Banner = styled.div`
    height: 65vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("/motorsportfamily.jpg") no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-top: 1em;
    box-sizing: border-box;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string().required("Email is required"),
    telephone: Yup.string().required("Telephone is required"),
    // address_line_one: Yup.string().required("Address Line 1 is required"),
    // address_line_two: Yup.string().required("Address Line 2 is required"),
    // town: Yup.string().required("Town is required"),
    // county: Yup.string().required("County is required"),
    // post_code: Yup.string().required("Postcode is required"),
    dietary_requirements: Yup.string(),
    accommodation_required: Yup.boolean(),
    single_or_double_occupancy: Yup.string(),
    number_of_complimentary_tickets: Yup.number().required("Number Of Complimentary Tickets is required"),
    guest_name: Yup.string(),
    additional_tickets: Yup.number(),
});

const NightOfMotorsport = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const queryString = new URLSearchParams(useLocation().search);

    const title = queryString.get("title")?.toLowerCase() || "";
    const first_name = queryString.get("first_name") || "";
    const surname = queryString.get("surname") || "";
    const email = queryString.get("email") || "";
    const telephone = queryString.get("telephone") || "";
    // const address_line_one = queryString.get("address_line_one") || "";
    // const address_line_two = queryString.get("address_line_two") || "";
    // const town = queryString.get("town") || "";
    // const county = queryString.get("county") || "";
    // const post_code = queryString.get("post_code") || "";
    const dietary_requirements = queryString.get("dietary_requirements") || "";
    const accommodation_required =
        queryString.get("accommodation_required") || false;
    const single_or_double_occupancy =
        queryString.get("single_or_double_occupancy") || "";
    const additional_tickets =
        queryString.get("additional_tickets") === "true" || 0;
    const two_tickets_overnight_stay_for_two =
        queryString.get("two_tickets_overnight_stay_for_two") === "true" ||
        false;
    const two_tickets_only =
        queryString.get("two_tickets_only") === "true" || false;
    const one_ticket_overnight_stay_for_one =
        queryString.get("one_ticket_overnight_stay_for_one") === "true" ||
        false;
    const number_of_complimentary_tickets = queryString.get("number_of_complimentary_tickets") || 0;
    const guest_name = queryString.get("guest_name") || "";


    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/motor-sport-event`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <Banner>
                <h1>
                    RSVP <br />
                    Porsche Night Of Motorsport
                </h1>
            </Banner>

            <Container>
                {submitSuccessful ? (
                    <>
                        <p>
                            Porsche Night Of Motorsport successfully submitted
                        </p>
                    </>
                ) : (
                    <>
                        {two_tickets_overnight_stay_for_two ||
                        two_tickets_only ||
                        one_ticket_overnight_stay_for_one ? (
                            <Formik
                                initialValues={{
                                    title: title,
                                    first_name: first_name,
                                    surname: surname,
                                    email: email,
                                    telephone: telephone,
                                    // address_line_one: address_line_one,
                                    // address_line_two: address_line_two,
                                    // town: town,
                                    // county: county,
                                    // post_code: post_code,
                                    dietary_requirements: dietary_requirements,
                                    accommodation_required: false,
                                    single_or_double_occupancy:
                                        single_or_double_occupancy,
                                    additional_tickets: additional_tickets,
                                    two_tickets_overnight_stay_for_two:
                                        two_tickets_overnight_stay_for_two,
                                    two_tickets_only: two_tickets_only,
                                    one_ticket_overnight_stay_for_one:
                                        one_ticket_overnight_stay_for_one,
                                    number_of_complimentary_tickets: 
                                        number_of_complimentary_tickets,
                                    guest_name: 
                                        guest_name,
                                }}
                                validationSchema={Schema}
                                onSubmit={handleOnSubmit}
                            >
                                {({
                                    errors,
                                    touched,
                                    setFieldValue,
                                    values,
                                }) => {
                                    return (
                                        <Form>
                                            <h3 style={{ marginTop: "3rem" }}>
                                                Personal Details
                                            </h3>

                                            <StyledLabel htmlFor="title">
                                                Title <Required />
                                            </StyledLabel>
                                            <StyledField
                                                value={values.title}
                                                as="select"
                                                name="title"
                                                onChange={(value: any) =>
                                                    setFieldValue(
                                                        "title",
                                                        value.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select</option>
                                                <option value="mr">Mr</option>
                                                <option value="mrs">Mrs</option>
                                                <option value="miss">
                                                    Miss
                                                </option>
                                                <option value="ms">Ms</option>
                                                <option value="other">
                                                    Other
                                                </option>
                                            </StyledField>
                                            {errors.title && touched.title ? (
                                                <Error>{errors.title}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="first_name">
                                                First name <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="first_name"
                                                name="first_name"
                                            />
                                            {errors.first_name &&
                                            touched.first_name ? (
                                                <Error>
                                                    {errors.first_name}
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="surname">
                                                Surname <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="surname"
                                                name="surname"
                                            />
                                            {errors.surname &&
                                            touched.surname ? (
                                                <Error>{errors.surname}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="email">
                                                Email <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="email"
                                                name="email"
                                            />
                                            {errors.email && touched.email ? (
                                                <Error>{errors.email}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="email">
                                                Telephone <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="telephone"
                                                name="telephone"
                                            />
                                            {errors.telephone &&
                                            touched.telephone ? (
                                                <Error>
                                                    {errors.telephone}
                                                </Error>
                                            ) : null}

                                            {/* <StyledLabel htmlFor="address_line_one">
                                                Address Line 1 <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="address_line_one"
                                                name="address_line_one"
                                            />
                                            {errors.address_line_one &&
                                            touched.address_line_one ? (
                                                <Error>
                                                    {errors.address_line_one}
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="address_line_two">
                                                Address Line 2 <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="address_line_two"
                                                name="address_line_two"
                                            />
                                            {errors.address_line_two &&
                                            touched.address_line_two ? (
                                                <Error>
                                                    {errors.address_line_two}
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="town">
                                                Town <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="town"
                                                name="town"
                                            />
                                            {errors.town && touched.town ? (
                                                <Error>{errors.town}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="county">
                                                County <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="county"
                                                name="county"
                                            />
                                            {errors.county && touched.county ? (
                                                <Error>{errors.county}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="post_code">
                                                Postcode <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="post_code"
                                                name="post_code"
                                            />
                                            {errors.post_code &&
                                            touched.post_code ? (
                                                <Error>
                                                    {errors.post_code}
                                                </Error>
                                            ) : null} */}

                                            <StyledLabel htmlFor="dietary_requirements">
                                                Dietary Requirements
                                            </StyledLabel>
                                            <StyledField
                                                id="dietary_requirements"
                                                name="dietary_requirements"
                                            />
                                            {errors.dietary_requirements &&
                                            touched.dietary_requirements ? (
                                                <Error>
                                                    {
                                                        errors.dietary_requirements
                                                    }
                                                </Error>
                                            ) : null}

                                            {(two_tickets_overnight_stay_for_two ||
                                                one_ticket_overnight_stay_for_one) && (
                                                <RadioContainerOuter>
                                                    <RadioContainer>
                                                        <CustomRadio
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    "accommodation_required",
                                                                    value.target
                                                                        .checked
                                                                );
                                                            }}
                                                            checked={
                                                                values.accommodation_required
                                                            }
                                                            // @ts-ignore
                                                            value={
                                                                values.accommodation_required
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <RadioCheck
                                                            square={true}
                                                            className="checkmark"
                                                        />
                                                        Please confirm if
                                                        accommodation is
                                                        required
                                                    </RadioContainer>
                                                </RadioContainerOuter>
                                            )}

                                            {(two_tickets_overnight_stay_for_two && values.accommodation_required) && (
                                                <>
                                                    <StyledLabel htmlFor="single_or_double_occupancy">
                                                        If yes, please confirm
                                                        if single or double
                                                        occupancy? <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        as="select"
                                                        name="single_or_double_occupancy"
                                                        onChange={(
                                                            value: any
                                                        ) =>
                                                            setFieldValue(
                                                                "single_or_double_occupancy",
                                                                value.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option value="single">
                                                            Single
                                                        </option>
                                                        <option value="double">
                                                            Double
                                                        </option>
                                                    </StyledField>
                                                    {errors.single_or_double_occupancy &&
                                                    touched.single_or_double_occupancy ? (
                                                        <Error>
                                                            {
                                                                errors.single_or_double_occupancy
                                                            }
                                                        </Error>
                                                    ) : null}
                                                </>
                                            )}

                                            {(two_tickets_overnight_stay_for_two || two_tickets_only) && (
                                                <>
                                                    <StyledLabel htmlFor="number_of_complimentary_tickets">
                                                        Number of complimentary tickets required <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        as="select"
                                                        name="number_of_complimentary_tickets"
                                                        onChange={(
                                                            value: any
                                                        ) =>  {
                                                            setFieldValue(
                                                                "number_of_complimentary_tickets",
                                                                value.target
                                                                    .value
                                                            )
                                                        }                                                              
                                                           
                                                        }
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option value="1">
                                                            1
                                                        </option>
                                                        <option value="2">
                                                            2
                                                        </option>
                                                    </StyledField>
                                                    {errors.number_of_complimentary_tickets &&
                                                    touched.number_of_complimentary_tickets ? (
                                                        <Error>
                                                            {
                                                                errors.number_of_complimentary_tickets
                                                            }
                                                        </Error>
                                                    ) : null}
                                                </>


                                            )}
                                            
                                            {(values.number_of_complimentary_tickets == 2) && (
                                                <>
                                                    <StyledLabel htmlFor="guest_name">
                                                        Guest Name
                                                    </StyledLabel>
                                                    <StyledField
                                                        id="guest_name"
                                                        name="guest_name"
                                                    />
                                                    {errors.guest_name &&
                                                    touched.guest_name ? (
                                                        <Error>{errors.guest_name}</Error>
                                                    ) : null}
                                                </>
                                            )}

                                            <StyledLabel htmlFor="additional_tickets">
                                                Purchase of any additional
                                                tickets at a cost of £95 + VAT
                                                each
                                            </StyledLabel>
                                            <StyledField
                                                id="additional_tickets"
                                                type="number"
                                                name="additional_tickets"
                                            />
                                            {errors.additional_tickets &&
                                            touched.additional_tickets ? (
                                                <Error>
                                                    {errors.additional_tickets}
                                                </Error>
                                            ) : null}

                                            {two_tickets_overnight_stay_for_two && (
                                                <>
                                                    <p>
                                                        If you would like an
                                                        additional room(s)
                                                        please contact the
                                                        Berystede Hotel directly
                                                        on 0344 879 9000. Please
                                                        quote the following
                                                        information for a
                                                        preferential rate:
                                                    </p>
                                                    <p>
                                                        Berystede
                                                        <br />
                                                        Porsche
                                                        <br />
                                                        Saturday 3 December
                                                        <br />
                                                    </p>
                                                    <p>
                                                        If you have requested
                                                        additional tickets a
                                                        member of the PCMS team
                                                        will be in contact to
                                                        provide details on
                                                        making payment.
                                                    </p>
                                                    <p>
                                                        For more information or
                                                        help with your booking
                                                        please contact PCMS on
                                                        01202 017 037.
                                                    </p>
                                                </>
                                            )}
                                            {two_tickets_only && (
                                                <>
                                                    <p>
                                                        Limited rooms are
                                                        available at the
                                                        Berystede Hotel on a
                                                        first come first serve
                                                        basis. Book now by
                                                        calling the hotel
                                                        directly on 0344 879
                                                        9000 and quoting the
                                                        below for a preferential
                                                        rate:
                                                    </p>
                                                    <p>
                                                        Berystede
                                                        <br />
                                                        Porsche
                                                        <br />
                                                        Saturday 3 December
                                                        <br />
                                                    </p>
                                                    <p>
                                                        If you have requested
                                                        additional tickets a
                                                        member of the PCMS team
                                                        will be in contact to
                                                        provide details on
                                                        making payment.
                                                    </p>
                                                    <p>
                                                        For more information or
                                                        help with your booking
                                                        please contact PCMS on
                                                        01202 017 037.
                                                    </p>
                                                </>
                                            )}
                                            {one_ticket_overnight_stay_for_one && (
                                                <>
                                                    <p>
                                                        If you would like an
                                                        additional room(s) at
                                                        the Berystede Hotel
                                                        please contact the hotel
                                                        directly on 0344 879
                                                        9000 and quote the
                                                        following information
                                                        for a preferential rate:
                                                    </p>
                                                    <p>
                                                        Berystede
                                                        <br />
                                                        Porsche
                                                        <br />
                                                        Saturday 3 December
                                                        <br />
                                                    </p>
                                                    <p>
                                                        If you have requested
                                                        additional tickets a
                                                        member of the PCMS team
                                                        will be in contact to
                                                        provide details on
                                                        making payment.
                                                    </p>
                                                    <p>
                                                        For more information or
                                                        help with your booking
                                                        please contact PCMS
                                                        01202 017 037.
                                                    </p>
                                                </>
                                            )}

                                            {submitError && (
                                                <Error>{submitError}</Error>
                                            )}
                                            <SubmitSection>
                                                <SubmitButton
                                                    hasArrow
                                                    color="red"
                                                    type="submit"
                                                >
                                                    Submit Details
                                                </SubmitButton>
                                                {submitting && <Loader />}
                                            </SubmitSection>
                                            <div
                                                style={{
                                                    paddingBottom: "2rem",
                                                }}
                                            >
                                                <Required /> Required Field
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        ) : (
                            <p>Invalid link - Event stream required</p>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default NightOfMotorsport;
