import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledRadioButton,
    StyledRadioButtonLabel,
    StyledTextarea,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string().required("Email is required"),
    registration: Yup.string().required("Vehicle Registration is required"),
    advisor_name: Yup.string().required("Service Advisor Name is required"),
    expectations_exceeded: Yup.bool().required(
        "Please select if expectations were exceeded"
    ),
    offered_inspection_of_dedicated_inspection_area: Yup.string().required(
        "PLease select if you were offered an inspection of the dedicated inspection area"
    ),
    recommend_service_centre: Yup.string().required(
        "Please select if you would recommend the service centre"
    ),
    vehicle_ready_on_time: Yup.string().required(
        "Please select if your vehicle was ready on time"
    ),
    expectations_exceeded_comments: Yup.string().required(
        "Please provide an explanation regarding your expectations"
    ),
    service_rating: Yup.number().required("Please select a service rating"),
});

const SheffieldServingSurvey = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const oneToTen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const serviceAdvisors = [
        "Clint Davis",
        "Chris Cusick",
        "Umar Ali",
        "Richard Wilkinson",
        "Kerry Morgan",
        "David Beecroft",
        "Katie Phillips",
        "Megan Golub",
    ];

    const switchToBoolean = (value: any) => {
        if (value === "true" || value === "yes") {
            return true;
        } else {
            return false;
        }
    };

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        values.expectations_exceeded = switchToBoolean(
            values.expectations_exceeded
        );
        values.offered_inspection_of_dedicated_inspection_area =
            switchToBoolean(
                values.offered_inspection_of_dedicated_inspection_area
            );
        values.recommend_service_centre = switchToBoolean(
            values.recommend_service_centre
        );
        values.vehicle_ready_on_time = switchToBoolean(
            values.vehicle_ready_on_time
        );

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/sheffield-servicing-survey`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo />
            </LogoContainer>

            <h1 className="title">Porsche Centre Sheffield - Service survey</h1>

            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <h2>Thank you</h2>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                title: "",
                                first_name: "",
                                surname: "",
                                email: "",
                                registration: "",
                                advisor_name: "",
                                expectations_exceeded: "",
                                expectations_exceeded_comments: "",
                                offered_inspection_of_dedicated_inspection_area:
                                    "",
                                recommend_service_centre: "",
                                vehicle_ready_on_time: "",
                                service_rating: "",
                                comments: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <StyledLabel htmlFor="title">
                                            Title <Required />
                                        </StyledLabel>
                                        <StyledField
                                            value={values.title}
                                            as="select"
                                            name="title"
                                            onChange={(value: any) =>
                                                setFieldValue(
                                                    "title",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="mr">Mr</option>
                                            <option value="mrs">Mrs</option>
                                            <option value="miss">Miss</option>
                                            <option value="ms">Ms</option>
                                            <option value="other">Other</option>
                                        </StyledField>
                                        {errors.title && touched.title ? (
                                            <Error>{errors.title}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="first_name">
                                            First name <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="first_name"
                                            name="first_name"
                                        />
                                        {errors.first_name &&
                                        touched.first_name ? (
                                            <Error>{errors.first_name}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="surname">
                                            Surname <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="surname"
                                            name="surname"
                                        />
                                        {errors.surname && touched.surname ? (
                                            <Error>{errors.surname}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField id="email" name="email" />
                                        {errors.email && touched.email ? (
                                            <Error>{errors.email}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="registration">
                                            Vehicle Registration <Required />
                                        </StyledLabel>
                                        <StyledField
                                            value={values.registration}
                                            id="registration"
                                            name="registration"
                                        />
                                        {errors.registration &&
                                        touched.registration ? (
                                            <Error>{errors.registration}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="advisor_name">
                                            Service Advisor Name <Required />
                                        </StyledLabel>
                                        <StyledField
                                            value={values.advisor_name}
                                            as="select"
                                            name="advisor_name"
                                            onChange={(value: any) =>
                                                setFieldValue(
                                                    "advisor_name",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option disabled={true} value="">
                                                Select
                                            </option>
                                            {serviceAdvisors.map((advisor) => {
                                                return (
                                                    <option value={advisor}>
                                                        {advisor}
                                                    </option>
                                                );
                                            })}
                                        </StyledField>
                                        {errors.advisor_name &&
                                        touched.advisor_name ? (
                                            <Error>{errors.advisor_name}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="expectations_exceeded">
                                            Has the handover exceeded your
                                            expectations? <Required />
                                        </StyledLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="expectations_exceeded"
                                                value={"true"}
                                            />
                                            Yes
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="expectations_exceeded"
                                                value={"false"}
                                            />
                                            No
                                        </StyledRadioButtonLabel>

                                        {errors.expectations_exceeded &&
                                        touched.expectations_exceeded ? (
                                            <Error>
                                                {errors.expectations_exceeded}
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="expectations_exceeded_comments">
                                            Please provide further details for
                                            your answer above.
                                            <Required />
                                        </StyledLabel>
                                        <StyledTextarea
                                            id="expectations_exceeded_comments"
                                            name="expectations_exceeded_comments"
                                            rows={5}
                                            placeholder="Enter your explanation here"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "expectations_exceeded_comments",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        {errors.expectations_exceeded_comments &&
                                        touched.expectations_exceeded_comments ? (
                                            <Error>
                                                {
                                                    errors.expectations_exceeded_comments
                                                }
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="offered_inspection_of_dedicated_inspection_area">
                                            Were you offered an inspection in
                                            our dedicated inspection area?{" "}
                                            <Required />
                                        </StyledLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="offered_inspection_of_dedicated_inspection_area"
                                                value={"true"}
                                            />
                                            Yes
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="offered_inspection_of_dedicated_inspection_area"
                                                value={"false"}
                                            />
                                            No
                                        </StyledRadioButtonLabel>

                                        <StyledLabel htmlFor="service_rating">
                                            How would you rate our Service
                                            Department on a scale of 1-10? 1
                                            being very poor and 10 being
                                            excellent. <Required />
                                        </StyledLabel>
                                        <StyledField
                                            value={values.service_rating}
                                            as="select"
                                            name="service_rating"
                                            onChange={(value: any) =>
                                                setFieldValue(
                                                    "service_rating",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option disabled={true} value="">
                                                Select
                                            </option>

                                            {oneToTen.map((number) => {
                                                return (
                                                    <option value={number}>
                                                        {number}
                                                    </option>
                                                );
                                            })}
                                        </StyledField>
                                        {errors.service_rating &&
                                        touched.service_rating ? (
                                            <Error>
                                                {errors.service_rating}
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="recommend_service_centre">
                                            Would you recommend our Service
                                            Department? <Required />
                                        </StyledLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="recommend_service_centre"
                                                value={"true"}
                                            />
                                            Yes
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="recommend_service_centre"
                                                value={"false"}
                                            />
                                            No
                                        </StyledRadioButtonLabel>

                                        {errors.recommend_service_centre &&
                                        touched.recommend_service_centre ? (
                                            <Error>
                                                {
                                                    errors.recommend_service_centre
                                                }
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="vehicle_ready_on_time">
                                            Was your vehicle available in the
                                            time scale agreed? <Required />
                                        </StyledLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="vehicle_ready_on_time"
                                                value={"true"}
                                            />
                                            Yes
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="vehicle_ready_on_time"
                                                value={"false"}
                                            />
                                            No
                                        </StyledRadioButtonLabel>

                                        {errors.vehicle_ready_on_time &&
                                        touched.vehicle_ready_on_time ? (
                                            <Error>
                                                {errors.vehicle_ready_on_time}
                                            </Error>
                                        ) : null}

                                        <StyledLabel htmlFor="comments">
                                            Any additional comments?
                                            <Required />
                                        </StyledLabel>
                                        <StyledTextarea
                                            id="comments"
                                            name="comments"
                                            rows={5}
                                            placeholder="Enter your addtional comments here"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "comments",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        {errors.comments && touched.comments ? (
                                            <Error>{errors.comments}</Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}

                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                Submit details
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>

                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default SheffieldServingSurvey;
