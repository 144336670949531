import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

const getColor = (props) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isFocused) {
        return "#2196f3";
    }
    return "#000000";
};

const Container = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 1px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #f3f3f3;
    color: #000000;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 1em;
    cursor: pointer;
`;

const ClearButton = styled.button`
    display: block;
    outline: none;
    padding: 1.2em 2em;
    font-weight: bold;
    cursor: pointer;

    background: white;
    border: 1px solid black;
    border-radius: 0;

    transition: all 0.1s;
    margin: 0 auto 1em;
`;

const StyledDropzone = ({
    children,
    showClearButton = false,
    onClear = () => {},
    ...rest
}) => {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({ ...rest });

    const handleClearClick = () => {
        onClear();
    };

    return (
        <div className="container">
            <Container
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
            >
                <input {...getInputProps()} />
                {children}
            </Container>
            {showClearButton && (
                <ClearButton onClick={handleClearClick}>
                    Clear uploads
                </ClearButton>
            )}
        </div>
    );
};

export default StyledDropzone;
