import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Logo from "../components/Logo";

import {
    Container,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledRadioButton,
    StyledRadioButtonLabel,
    StyledTextarea,
} from "../components/FormComponents";

const PCMC2 = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

    const [uuid, setUuid] = useState("");
    const questions = [
        "network_feel_supported",
        "received_enough_information",
        "method_of_receiving_non_urgent_updates",
        // "other_method_of_receiving_non_urgent_updates", //This is only required if method_of_receiving_non_urgent_updates === "other"
        "aware_of_main_pain_points",
        "areas_of_improvement",
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        //Inject uuid into values
        values.uuid = uuid;

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/management-conference/survey`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitError("");
                setUuid(data.data.uuid);
                if (currentQuestionIndex + 1 > questions.length) {
                    setSubmitSuccessful(true);
                } else {
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                }
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    const errors = data.error;
                    const currentQuestionIndexKey =
                        questions[currentQuestionIndex];
                    if (errors[currentQuestionIndexKey]) {
                        setSubmitError(errors[currentQuestionIndexKey][0]);
                    } else {
                        setCurrentQuestionIndex(currentQuestionIndex + 1);
                        setSubmitError("");
                    }
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    const validate = (values: any) => {
        const errors = {};

        return errors;
    };

    const checkValuesAreCurrentlyValid = (touchedValues: any) => {
        setSubmitIsDisabled(true);

        const currentQuestion = questions[currentQuestionIndex];

        const isStepValid =
            touchedValues[currentQuestion] !== undefined &&
            touchedValues[currentQuestion] !== "";

        setSubmitIsDisabled(!isStepValid);
    };

    /**
     * TODO add proper types
     * @param values:
     * @param errors
     * @param touched
     * @returns
     */
    const renderCurrentQuestion = (
        values: any,
        errors: any,
        touched: any,
        setFieldValue: any
    ) => {
        checkValuesAreCurrentlyValid(values);

        switch (currentQuestionIndex) {
            case 0:
                return (
                    <>
                        <StyledLabel htmlFor="network_feel_supported">
                            Following last winter and the upcoming campaign
                            affecting xxx, as a network, do you feel supported?{" "}
                            <Required />
                        </StyledLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="network_feel_supported"
                                value="yes"
                            />
                            Yes
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="network_feel_supported"
                                value="no"
                            />
                            No
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="network_feel_supported"
                                value="partially"
                            />
                            Partially, but more support could be available
                        </StyledRadioButtonLabel>

                        {errors.network_feel_supported &&
                        touched.network_feel_supported ? (
                            <Error>{errors.network_feel_supported}</Error>
                        ) : null}
                    </>
                );
            case 1:
                return (
                    <>
                        <StyledLabel htmlFor="received_enough_information">
                            Do you feel that you receive enough information from
                            PCGB, with enough notice? (Recall/Campaigns, update
                            to Warranty terms, update to PCIC processes etc){" "}
                            <Required />
                        </StyledLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="received_enough_information"
                                value="yes"
                            />
                            Yes
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="received_enough_information"
                                value="no"
                            />
                            No
                        </StyledRadioButtonLabel>

                        {errors.received_enough_information ? (
                            <Error>{errors.received_enough_information}</Error>
                        ) : null}
                    </>
                );
            case 2:
                return (
                    <>
                        <StyledLabel htmlFor="method_of_receiving_non_urgent_updates">
                            How would you prefer to receive non-urgent updates
                            from PCGB? <Required />
                        </StyledLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="method_of_receiving_non_urgent_updates"
                                value="network_wide_email_reactive_to_updates"
                            />
                            Network wide email reactive to updates
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="method_of_receiving_non_urgent_updates"
                                value="weekly_round_up_email_including_multiple_topics"
                            />
                            Weekly round up email including multiple topics
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="method_of_receiving_non_urgent_updates"
                                value="ppn_bulletins"
                            />
                            PPN bulletins
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="method_of_receiving_non_urgent_updates"
                                value="other"
                            />
                            Other
                        </StyledRadioButtonLabel>

                        {values.method_of_receiving_non_urgent_updates ===
                            "other" && (
                            <StyledTextarea
                                name="other_method_of_receiving_non_urgent_updates"
                                placeholder="Please specify"
                                onChange={(e) => {
                                    setFieldValue(
                                        "other_method_of_receiving_non_urgent_updates",
                                        e.target.value
                                    );
                                }}
                            />
                        )}

                        {errors.method_of_receiving_non_urgent_updates &&
                        touched.method_of_receiving_non_urgent_updates ? (
                            <Error>
                                {errors.method_of_receiving_non_urgent_updates}
                            </Error>
                        ) : null}
                    </>
                );
            case 3:
                return (
                    <>
                        {" "}
                        <StyledLabel htmlFor="aware_of_main_pain_points">
                            Thinking of our brand strategy and moving into the
                            Premium Luxury segment and exceeding customer
                            expectations, do you feel that PCGB are aware of the
                            main pain points affecting aftersales? <Required />
                        </StyledLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="aware_of_main_pain_points"
                                value="yes"
                            />
                            Yes
                        </StyledRadioButtonLabel>
                        <StyledRadioButtonLabel>
                            <StyledRadioButton
                                type="radio"
                                name="aware_of_main_pain_points"
                                value="no"
                            />
                            No
                        </StyledRadioButtonLabel>
                        {errors.aware_of_main_pain_points &&
                        touched.aware_of_main_pain_points ? (
                            <Error>{errors.aware_of_main_pain_points}</Error>
                        ) : null}
                    </>
                );
            case 4:
                return (
                    <>
                        <StyledLabel htmlFor="areas_of_improvement">
                            Outside of capacity and campaign related activity,
                            are there any areas that we need to improve for us
                            to achieve our brand strategy for aftersales?
                            <Required />
                        </StyledLabel>
                        <StyledTextarea
                            name="areas_of_improvement"
                            rows={5}
                            placeholder="Please specify"
                            onChange={(e) => {
                                setFieldValue(
                                    "areas_of_improvement",
                                    e.target.value
                                );
                            }}
                        />

                        {errors.areas_of_improvement ? (
                            <Error>{errors.areas_of_improvement}</Error>
                        ) : null}
                    </>
                );
        }
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <h1 className="title">Porsche Centre Management Conference 2023</h1>

            <Break />

            <>
                {submitSuccessful ||
                currentQuestionIndex + 1 > questions.length ? (
                    <>
                        <h2>Thank you</h2>
                        <p></p>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                network_feel_supported: "",
                                received_enough_information: "",
                                method_of_receiving_non_urgent_updates: "",
                                other_method_of_receiving_non_urgent_updates:
                                    "",
                                aware_of_main_pain_points: "",
                                areas_of_improvement: "",
                            }}
                            // validationSchema={validate}
                            validate={validate}
                            onSubmit={handleOnSubmit}
                        >
                            {({
                                errors,
                                touched,
                                setFieldValue,
                                values,
                                ...rest
                            }) => {
                                // console.log({ values });

                                return (
                                    <Form>
                                        <h3>
                                            Question {currentQuestionIndex + 1}{" "}
                                            of {questions.length}
                                        </h3>
                                        {renderCurrentQuestion(
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue
                                        )}
                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                disabled={submitIsDisabled}
                                                color="red"
                                                type="submit"
                                            >
                                                {submitIsDisabled
                                                    ? "Please enter an answer"
                                                    : questions.length ===
                                                      currentQuestionIndex + 1
                                                    ? "Submit Details"
                                                    : "Continue"}
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required Field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default PCMC2;
