import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import axios from "axios";

import {
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    FlexBox,
    RadioContainerOuter,
    RadioContainer,
    CustomRadio,
    RadioCheck
} from "../components/FormComponents";

import Logo from "../components/Logo";
import CenterSelector from "../components/CenterSelector";


const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First Name is required"),
    surname: Yup.string().required("Surname is required"),
    porsche_centre: Yup.string().required("Porsche Centre is required"),
    role: Yup.string().required("Role is required"),
    telephone: Yup.string().required("Telephone is required"),
    email: Yup.string().required("Email is required"),
    daytime_event_confirmation: Yup.boolean(),
    evening_event_confirmation: Yup.boolean(),
    dietary_requirements: Yup.string(),
    extra_people: Yup.array().of(
        Yup.object().shape({
            title: Yup.string(),
            first_name: Yup.string(),
            surname: Yup.string(),
            porsche_centre: Yup.string(),
            role: Yup.string(),
            telephone: Yup.string(),
            email: Yup.string(),
            daytime_event_confirmation: Yup.boolean(),
            evening_event_confirmation: Yup.boolean(),
            dietary_requirements: Yup.string(),
        })
    ),
    personal_data: Yup.boolean().required("Personal data agreement is required"),
    privacy_policy: Yup.boolean().required("Privacy policy is required"),
});

const MarketingAwardsContainer = styled.div`
    display: flex;
    padding: 4em;
    max-width: 700px;
    margin: 0 auto;
    
    @media (max-width: 800px) {
        display: block;
    }
`;

const FormContainer = styled.div`
    flex-grow: 1;
`;

const Footer = styled.div`
    background: #191f23;
    color: white;
    padding: 2em;
`;

const UnstyledButton = styled.button`
    background: none;
    outline: none;
    border: none;
    padding: none;
    text-decoration: underline;
    cursor: pointer;
`;


const MarketingAwards = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/marketing-awards`,
                values,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    type MyFormValues = {
        title: string,
        first_name: string,
        surname: string,
        porsche_centre: string,
        role: string,
        telephone: string,
        email: string,
        dietary_requirements: string,
        daytime_event_confirmation: boolean,
        evening_event_confirmation: boolean,
        extra_people: {
            title: string,
            first_name: string,
            surname: string,
            porsche_centre: string,
            role: string,
            telephone: string,
            email: string,
            daytime_event_confirmation: boolean,
            evening_event_confirmation: boolean,
            dietary_requirements: string,
        }[],
        personal_data: boolean,
        privacy_policy: boolean,
    }

    const initialValues: MyFormValues = {
        title: '',
        first_name: '',
        surname: '',
        porsche_centre: '',
        role: '',
        telephone: '',
        email: '',
        dietary_requirements: '',
        daytime_event_confirmation: false,
        evening_event_confirmation: false,
        extra_people: [],
        personal_data: false,
        privacy_policy: false,
    };

    return (
        <>
            <LogoContainer style={{ marginBottom: '1em' }}>
                <Logo style={{ width: '100px' }}/>
            </LogoContainer>
            <MarketingAwardsContainer>

                <FormContainer>
                    {submitSuccessful ? (
                        <>
                            <p>Thank you for confirming your place at this year's Retail Marketing Round Table and Awards.</p>
                        </>
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {

                                const handleExtraPeopleChange = (value: string | boolean, field: string, index: number) => {
                                    setFieldValue(
                                        "extra_people",
                                        [
                                            ...values.extra_people.map((person: {}, index2) => {
                                                if (index === index2) {
                                                    return {
                                                        ...person,
                                                        [field]: value
                                                    }
                                                } else {
                                                    return person;
                                                }
                                            }),
                                        ]
                                    )
                                };

                                return (
                                    <Form>
                                        <h1>
                                            Retail Marketing Round Table and Awards.
                                        </h1>
                                        <p>To confirm your attendance for this year's Retail Marketing Round Table and Awards please fill out the sign up form below. You will need to complete one for each individual attending the event.</p>

                                        <br />
                                        <p><b>Personal Details - Individual 1</b></p>
                                        <br />

                                        <StyledLabel htmlFor="title">
                                            Title <Required />
                                        </StyledLabel>
                                        <StyledField
                                            style={{ padding: '0.7em' }}
                                            as="select"
                                            name="title"
                                            onChange={(value: any) =>
                                                setFieldValue(
                                                    "title",
                                                    value.target.value
                                                )
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="mr">Mr</option>
                                            <option value="mrs">Mrs</option>
                                            <option value="miss">Miss</option>
                                            <option value="ms">Ms</option>
                                        </StyledField>
                                        {errors.title && touched.title ? (
                                            <Error>{errors.title}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="first_name">
                                            First name <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="first_name"
                                            name="first_name"
                                        />
                                        {errors.first_name && touched.first_name ? (
                                            <Error>{errors.first_name}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="surname">
                                            Surname <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="surname"
                                            name="surname"
                                        />
                                        {errors.surname && touched.surname ? (
                                            <Error>{errors.surname}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="porsche_centre">
                                            Porsche Centre <Required />
                                        </StyledLabel>

                                        <CenterSelector
                                            value={values.porsche_centre}
                                            name="porsche_centre"
                                            onChange={setFieldValue}
                                        />

                                        {errors.porsche_centre && touched.porsche_centre ? (
                                            <Error>{errors.porsche_centre}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="role">
                                            Role <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="role"
                                            name="role"
                                        />
                                        {errors.role && touched.role ? (
                                            <Error>{errors.role}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="telephone">
                                            Telephone <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="telephone"
                                            name="telephone"
                                        />
                                        {errors.telephone && touched.telephone ? (
                                            <Error>{errors.telephone}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="email">
                                            Email <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="email"
                                            name="email"
                                        />
                                        {errors.email && touched.email ? (
                                            <Error>{errors.email}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="dietary_requirements">
                                            Dietary Requirements **
                                        </StyledLabel>
                                        <StyledField
                                            id="dietary_requirements"
                                            name="dietary_requirements"
                                        />
                                        {errors.dietary_requirements && touched.dietary_requirements ? (
                                            <Error>{errors.dietary_requirements}</Error>
                                        ) : null}

                                        <RadioContainerOuter>
                                            <RadioContainer>
                                                <CustomRadio
                                                    onChange={(value: any) => {
                                                        setFieldValue(
                                                            "daytime_event_confirmation",
                                                            value.target
                                                                .checked
                                                        );
                                                    }}
                                                    checked={
                                                        values.daytime_event_confirmation
                                                    }
                                                    // @ts-ignore
                                                    value={
                                                        values.daytime_event_confirmation
                                                    }
                                                    type="checkbox"
                                                />
                                                <RadioCheck
                                                    square={true}
                                                    className="checkmark"
                                                />
                                                I confirm that I will be attending the Retail Marketing Round Table daytime event
                                            </RadioContainer>
                                        </RadioContainerOuter>

                                        {errors.daytime_event_confirmation &&
                                        touched.daytime_event_confirmation ? (
                                            <Error>
                                                {errors.daytime_event_confirmation}
                                            </Error>
                                        ) : null}

                                        <RadioContainerOuter>
                                            <RadioContainer>
                                                <CustomRadio
                                                    onChange={(value: any) => {
                                                        setFieldValue(
                                                            "evening_event_confirmation",
                                                            value.target
                                                                .checked
                                                        );
                                                    }}
                                                    checked={
                                                        values.evening_event_confirmation
                                                    }
                                                    // @ts-ignore
                                                    value={
                                                        values.evening_event_confirmation
                                                    }
                                                    type="checkbox"
                                                />
                                                <RadioCheck
                                                    square={true}
                                                    className="checkmark"
                                                />
                                                I confirm that I will be attending the Retail Marketing Awards evening event
                                            </RadioContainer>
                                        </RadioContainerOuter>

                                        {errors.evening_event_confirmation &&
                                        touched.evening_event_confirmation ? (
                                            <Error>
                                                {errors.evening_event_confirmation}
                                            </Error>
                                        ) : null}

                                        {values.extra_people.map((extra_person, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{ marginTop: '2rem' }}
                                                >
                                                    <Break />

                                                    <br />
                                                    <p style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <b>Personal Details - Individual {index + 2}</b>
                                                        <UnstyledButton
                                                            type="button"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setFieldValue(
                                                                    "extra_people",
                                                                    values.extra_people.filter((person, i) => index !== i)
                                                                );
                                                            }}
                                                        >
                                                            Remove
                                                        </UnstyledButton>
                                                    </p>
                                                    <br />

                                                    <StyledLabel htmlFor={`title_${index}`}>
                                                        Title <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].title}
                                                        style={{ padding: '0.7em' }}
                                                        as="select"
                                                        name={`title_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "title", index)
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="mr">Mr</option>
                                                        <option value="mrs">Mrs</option>
                                                        <option value="miss">Miss</option>
                                                        <option value="ms">Ms</option>
                                                    </StyledField>


                                                    <StyledLabel htmlFor={`first_name_${index}`}>
                                                        First name
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people ? values.extra_people[index].first_name : ""}
                                                        id={`first_name_${index}`}
                                                        name={`first_name_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "first_name", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`surname_${index}`}>
                                                        Surname
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].surname}
                                                        id={`surname_${index}`}
                                                        name={`surname_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "surname", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`porsche_centre_${index}`}>
                                                        Porsche Centre
                                                    </StyledLabel>
                                                    <CenterSelector
                                                        value={values.extra_people[index].porsche_centre}
                                                        name={`porsche_centre_${index}`}
                                                        onChange={() => {}}
                                                        customOnChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "porsche_centre", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`role_${index}`}>
                                                        Role
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].role}
                                                        id={`role_${index}`}
                                                        name={`role_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "role", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`telephone_${index}`}>
                                                        Telephone
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].telephone}
                                                        id={`telephone_${index}`}
                                                        name={`telephone_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "telephone", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`email_${index}`}>
                                                        Email
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].email}
                                                        id={`email_${index}`}
                                                        name={`email_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "email", index)
                                                        }
                                                    />

                                                    <StyledLabel htmlFor={`dietary_requirements_${index}`}>
                                                        Dietary Requirements
                                                    </StyledLabel>
                                                    <StyledField
                                                        value={values.extra_people[index].dietary_requirements}
                                                        id={`dietary_requirements_${index}`}
                                                        name={`dietary_requirements_${index}`}
                                                        onChange={(value: any) =>
                                                            handleExtraPeopleChange(value.target.value, "dietary_requirements", index)
                                                        }
                                                    />

                                                    <RadioContainerOuter>
                                                        <RadioContainer>
                                                            <CustomRadio
                                                                onChange={(value: any) => {
                                                                    handleExtraPeopleChange(value.target.checked, "daytime_event_confirmation", index)
                                                                }}
                                                                checked={
                                                                    values.extra_people[index].daytime_event_confirmation
                                                                }
                                                                // @ts-ignore
                                                                value={
                                                                    values.extra_people[index].daytime_event_confirmation
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <RadioCheck
                                                                square={true}
                                                                className="checkmark"
                                                            />
                                                            I confirm that I will be attending the Retail Marketing Round Table daytime event
                                                        </RadioContainer>
                                                    </RadioContainerOuter>

                                                    <RadioContainerOuter>
                                                        <RadioContainer>
                                                            <CustomRadio
                                                                onChange={(value: any) => {
                                                                    handleExtraPeopleChange(value.target.checked, "evening_event_confirmation", index)
                                                                }}
                                                                checked={
                                                                    values.extra_people[index].evening_event_confirmation
                                                                }
                                                                // @ts-ignore
                                                                value={
                                                                    values.extra_people[index].evening_event_confirmation
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <RadioCheck
                                                                square={true}
                                                                className="checkmark"
                                                            />
                                                            I confirm that I will be attending the Retail Marketing Awards evening event
                                                        </RadioContainer>
                                                    </RadioContainerOuter>

                                                    {errors.evening_event_confirmation &&
                                                    touched.evening_event_confirmation ? (
                                                        <Error>
                                                            {errors.evening_event_confirmation}
                                                        </Error>
                                                    ) : null}
                                                </div>
                                            )
                                        })}

                                        <div style={{ margin: '1rem 0' }}>
                                            <SubmitButton
                                                hasArrow
                                                color="grey"
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setFieldValue(
                                                        "extra_people",
                                                        [
                                                            ...values.extra_people,
                                                            {
                                                                title: '',
                                                                first_name: '',
                                                                surname: '',
                                                                porsche_centre: '',
                                                                role: '',
                                                                telephone: '',
                                                                email: '',
                                                                daytime_event_confirmation: false,
                                                                evening_event_confirmation: false,
                                                                dietary_requirements: '',
                                                            }
                                                        ]
                                                    );
                                                }}
                                            >
                                                Add another individual
                                            </SubmitButton>
                                            <p>Please note that places for additional attendees are subject to availability and PCGB approval.</p>
                                        </div>

                                        <p style={{ marginTop: '3rem' }}> Details of how Porsche Cars Great Britain ("we", "us" and "Porsche") may use the personal data we have collected are set out in our privacy notice available here <a href="https://www.porsche.com/uk/privacy-policy/" target="_blank">https://www.porsche.com/uk/privacy-policy/</a> </p>

                                        <RadioContainerOuter>
                                            <RadioContainer>
                                                <CustomRadio
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            "personal_data",
                                                            value.target
                                                                .checked
                                                        );
                                                    }}
                                                    checked={
                                                        values.personal_data
                                                    }
                                                    // @ts-ignore
                                                    value={
                                                        values.personal_data
                                                    }
                                                    type="checkbox"
                                                />
                                                <RadioCheck
                                                    square={true}
                                                    className="checkmark"
                                                />
                                                ** By ticking here, you consent to Porsche processing your dietary requirements.  We will use the personal data you have provided during sign up for the purposes of managing the event and any catering involved.  Ticking here and providing your consent is completely optional and you can withdraw your consent at any time by contacting Kellie Yardley via <a href="mailto:kellie.yardley@porsche.co.uk">kellie.yardley@porsche.co.uk</a>
                                                {" "}<Required />
                                            </RadioContainer>
                                        </RadioContainerOuter>

                                        {errors.personal_data &&
                                        touched.personal_data ? (
                                            <Error>
                                                {errors.personal_data}
                                            </Error>
                                        ) : null}

                                        <RadioContainerOuter>
                                            <RadioContainer>
                                                <CustomRadio
                                                    onChange={(value: any) => {
                                                        setFieldValue(
                                                            "privacy_policy",
                                                            value.target
                                                                .checked
                                                        );
                                                    }}
                                                    checked={
                                                        values.privacy_policy
                                                    }
                                                    // @ts-ignore
                                                    value={
                                                        values.privacy_policy
                                                    }
                                                    type="checkbox"
                                                />
                                                <RadioCheck
                                                    square={true}
                                                    className="checkmark"
                                                />
                                                I agree to the{" "}
                                                <a
                                                    style={{ color: 'black' }}
                                                    href="https://www.porsche.com/uk/privacy-policy/"
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </a>
                                                {" "}<Required />
                                            </RadioContainer>
                                        </RadioContainerOuter>

                                        {errors.privacy_policy &&
                                        touched.privacy_policy ? (
                                            <Error>
                                                {errors.privacy_policy}
                                            </Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                Submit Details
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <Required /> Required Field
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </FormContainer>
            </MarketingAwardsContainer>
            <Footer>
                &copy; 2021 All rights reserved to Porsche Cars Great Britain Limited and its licensors. Privacy Policy | Legal Notice
            </Footer>
        </>
    );
};

export default MarketingAwards;
