import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    VictoryBar,
    VictoryPie,
    VictoryChart,
    VictoryAxis,
    VictoryLabel,
} from "victory";
import styled from "styled-components";

import Logo from "../components/Logo";

import {
    SubmitSection,
    SubmitButton,
    Loader,
    LogoContainer,
    Break,
} from "../components/FormComponents";
import { porscheTheme } from "../themes/porscheTheme";

const Container = styled.div`
    position: relative;
    max-width: 80vw;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem;
`;

const Slide = styled.div`
    max-height: 300px;
`;
const Question = styled.h2`
    margin-bottom: 1em;
`;

const Results = styled.div`
    margin-bottom: 2em;
    /* max-height: 500px; */
`;

const TagCloud = styled.div`
    display: flex;
    flex-wrap: wrap;
    p {
        margin: 0.5em;
        padding: 0.5em;
        border: 1px solid black;
        border-radius: 5px;
    }
`;

const ButtonRow = styled.div`
    position: fixed;
    bottom: 0;
    right: 2rem;
    text-align: right;
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
`;

const PCMC2Results = () => {
    const slides = [
        "network_feel_supported",
        "received_enough_information",
        "method_of_receiving_non_urgent_updates",
        "aware_of_main_pain_points",
        "areas_of_improvement",
    ];
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [results, setResults] = useState<any>({});

    useEffect(() => {
        //Poll for results every 5 seconds
        const interval = setInterval(() => {
            loadResults();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const loadResults = () => {
        setRefreshing(true);

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/porsche/management-conference/survey/statistics`
            )
            .then(({ data }) => {
                console.log(data.data);
                setRefreshing(false);
                setLoading(false);
                setResults(data.data);
            })
            .catch((e) => {
                console.log(e);
                setRefreshing(false);
                setLoading(false);
            });
    };

    const renderCurrentSlide = (currentIndex: number) => {
        const currentSlideKey = slides[currentIndex];

        const currentSlideResults = results[currentSlideKey];
        // console.log(results, currentSlideKey, currentSlideResults);
        // return <pre>{JSON.stringify(results[currentSlide], null, 2)}</pre>;
        if (!currentSlideResults || loading) return <Loader />;

        switch (currentIndex) {
            case 0:
                const firstQuestionResults = Object.entries(
                    currentSlideResults
                ).map(([key, value]) => ({
                    question: key,
                    count: value,
                }));
                return (
                    <Slide>
                        <Question>
                            Following last winter and the upcoming campaign
                            affecting xxx, as a network, do you feel supported?{" "}
                        </Question>
                        <Results>
                            <VictoryChart
                                domainPadding={20}
                                theme={porscheTheme}
                                animate={{ duration: 1000, easing: "bounce" }}
                                height={200}
                                padding={{
                                    top: 20,
                                    bottom: 30,
                                    left: 50,
                                    right: 50,
                                }}
                            >
                                <VictoryAxis
                                    tickValues={["yes", "no", "partially"]}
                                    tickFormat={["Yes", "No", "Partially"]}
                                />
                                <VictoryAxis
                                    dependentAxis
                                    tickFormat={(x) => `${x}`}
                                />
                                <VictoryBar
                                    data={firstQuestionResults}
                                    x="question"
                                    y="count"
                                />
                            </VictoryChart>
                        </Results>
                        {/* <pre>
                            {JSON.stringify(data, null, 2)}
                            {JSON.stringify(results[currentSlide], null, 2)}
                        </pre>
                        Partially, but more support could be available */}
                    </Slide>
                );
            case 1:
                let secondQuestionResults = [
                    { x: "Yes", y: 0 },
                    { x: "No", y: 0 },
                ];
                //Type this object
                const secondQuestionAnswers: { yes: string; no: string } = {
                    yes: "Yes",
                    no: "No",
                };
                //@ts-ignore
                secondQuestionResults = Object.entries(currentSlideResults).map(
                    ([key, value]) => ({
                        //@ts-ignore
                        x: secondQuestionAnswers[key],
                        y: value,
                    })
                );
                return (
                    <Slide>
                        <Question>
                            Do you feel that you receive enough information from
                            PCGB, with enough notice? (Recall/Campaigns, update
                            to Warranty terms, update to PCIC processes etc){" "}
                        </Question>

                        <VictoryPie
                            theme={porscheTheme}
                            height={200}
                            data={secondQuestionResults}
                            animate={{ duration: 2000 }}
                        />
                    </Slide>
                );
            case 2:
                const thirdQuestionResults = Object.entries(
                    currentSlideResults
                ).map(([key, value]) => ({
                    question: key,
                    count: value,
                }));
                return (
                    <Slide>
                        <Question>
                            How would you prefer to receive non-urgent updates
                            from PCGB?
                        </Question>
                        <Results>
                            <VictoryChart
                                domainPadding={20}
                                theme={porscheTheme}
                                animate={{ duration: 1000, easing: "bounce" }}
                                height={200}
                                padding={{
                                    top: 20,
                                    bottom: 30,
                                    left: 50,
                                    right: 50,
                                }}
                            >
                                <VictoryAxis
                                    tickValues={[
                                        "network_wide_email_reactive_to_updates",
                                        "weekly_round_up_email_including_multiple_topics",
                                        "ppn_bulletins",
                                        "other",
                                    ]}
                                    tickFormat={[
                                        "Network wide email ",
                                        "Weekly round up email ",
                                        "PPN bulletins",
                                        "Other",
                                    ]}
                                    // fixLabelOverlap={true}
                                    // axisLabelComponent={
                                    //     <VictoryLabel dy={20} />
                                    // }
                                    style={{
                                        tickLabels: {
                                            angle: -40,
                                            fontSize: 5,
                                        },
                                    }}
                                />
                                <VictoryAxis
                                    dependentAxis
                                    // tickFormat={(x) => `${x}`}
                                    tickFormat={(t) =>
                                        Number.isInteger(t) ? t : null
                                    }
                                />
                                <VictoryBar
                                    data={thirdQuestionResults}
                                    x="question"
                                    y="count"
                                />
                            </VictoryChart>
                        </Results>
                        {/* <pre>
                            {JSON.stringify(results[currentSlideKey], null, 2)}
                        </pre>
                        Network wide email reactive to updates Weekly round up
                        email including multiple topics PPN bulletins Other */}
                    </Slide>
                );
            case 3:
                let forthQuestionResults = [
                    { x: "Yes", y: 0 },
                    { x: "No", y: 0 },
                ];
                //Type this object
                const forthQuestionAnswers: { yes: string; no: string } = {
                    yes: "Yes",
                    no: "No",
                };
                //@ts-ignore
                forthQuestionResults = Object.entries(currentSlideResults).map(
                    ([key, value]) => ({
                        //@ts-ignore
                        x: forthQuestionAnswers[key],
                        y: value,
                    })
                );
                return (
                    <Slide>
                        {" "}
                        <Question>
                            Thinking of our brand strategy and moving into the
                            Premium Luxury segment and exceeding customer
                            expectations, do you feel that PCGB are aware of the
                            main pain points affecting aftersales?
                        </Question>
                        <Results>
                            <VictoryPie
                                theme={porscheTheme}
                                height={200}
                                data={forthQuestionResults}
                                animate={{ duration: 2000 }}
                            />
                        </Results>
                        {/* <pre>
                            {JSON.stringify(results[currentSlideKey], null, 2)}
                        </pre> */}
                    </Slide>
                );
            case 4:
                return (
                    <Slide>
                        <Question>
                            Outside of capacity and campaign related activity,
                            are there any areas that we need to improve for us
                            to achieve our brand strategy for aftersales?
                        </Question>
                        <TagCloud>
                            {Object.keys(currentSlideResults.other).map(
                                (answer) => {
                                    return (
                                        <p>
                                            {answer}:{" "}
                                            {currentSlideResults.other[answer]}
                                        </p>
                                    );
                                }
                            )}
                        </TagCloud>
                        {/* <pre>
                            {JSON.stringify(results[currentSlideKey], null, 2)}
                        </pre> */}
                    </Slide>
                );
        }
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>
            <h1 className="title">Porsche Centre Management Conference 2023</h1>
            <Break />
            {renderCurrentSlide(currentSlideIndex)}
            <ButtonRow>
                <SubmitButton
                    onClick={() => setCurrentSlideIndex(currentSlideIndex - 1)}
                    color="red"
                >
                    Previous
                </SubmitButton>
                <SubmitButton
                    onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
                    color="red"
                >
                    Next
                </SubmitButton>
            </ButtonRow>
        </Container>
    );
};

export default PCMC2Results;
