import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    Break,
    StyledRadioButton,
    StyledRadioButtonLabel,
    StyledTextarea,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    topic_area: Yup.string().required("Please select a topic area"),
    feedback: Yup.string().required("Please enter your feedback"),
});

const PCMC1 = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/porsche/management-conference/feedback`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer style={{ marginBottom: "1em" }}>
                <Logo style={{ width: "100px" }} />
            </LogoContainer>

            <h1 className="title">Porsche Centre Management Conference 2023</h1>

            <Break />

            <>
                {submitSuccessful ? (
                    <>
                        <h2>Thank you</h2>
                        <p>
                            If we are unable to answer your questions on the
                            day, we will follow up with an FAQ document post
                            conference.
                        </p>
                    </>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                topic_area: "",
                                feedback: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={handleOnSubmit}
                        >
                            {({ errors, touched, setFieldValue, values }) => {
                                // console.log({ errors, values });
                                return (
                                    <Form>
                                        <StyledLabel htmlFor="topic_area">
                                            Select the topic area for which you
                                            wish to provide feedback, or submit
                                            a question
                                            <Required />
                                        </StyledLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="Owner Services (Service, PAW, Porsche Passion, Mobility, Porsche Assistance)"
                                            />
                                            Owner Services (Service, PAW,
                                            Porsche Passion, Mobility, Porsche
                                            Assistance)
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="Service Quality (New Car Warranty, Technical)"
                                            />
                                            Service Quality (New Car Warranty,
                                            Technical)
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="Service Qualification"
                                            />
                                            Service Qualification
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="Product and Technical Compliance"
                                            />
                                            Product and Technical Compliance
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="Parts"
                                            />
                                            Parts
                                        </StyledRadioButtonLabel>
                                        <StyledRadioButtonLabel>
                                            <StyledRadioButton
                                                type="radio"
                                                name="topic_area"
                                                value="PCIC"
                                            />
                                            PCIC
                                        </StyledRadioButtonLabel>
                                        {errors.topic_area &&
                                        touched.topic_area ? (
                                            <Error>{errors.topic_area}</Error>
                                        ) : null}

                                        <StyledLabel htmlFor="feedback">
                                            Please submit your question or
                                            feedback below
                                            <Required />
                                        </StyledLabel>
                                        <StyledTextarea
                                            id="feedback"
                                            name="feedback"
                                            rows={5}
                                            placeholder="Enter your feedback here"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "feedback",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        {errors.feedback && touched.feedback ? (
                                            <Error>{errors.feedback}</Error>
                                        ) : null}

                                        {submitError && (
                                            <Error>{submitError}</Error>
                                        )}
                                        <SubmitSection>
                                            <SubmitButton
                                                hasArrow
                                                color="red"
                                                type="submit"
                                            >
                                                Submit details
                                            </SubmitButton>
                                            {submitting && <Loader />}
                                        </SubmitSection>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <Required /> Required field
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
            </>
        </Container>
    );
};

export default PCMC1;
