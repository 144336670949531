import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
    RadioContainer,
    CustomRadio,
    RadioCheck,
    RadioContainerOuter,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    type: Yup.string().required(),
    src: Yup.string().required(),
    name: Yup.string().required(),
    email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),
});

const UnsubscribeForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const queryString = new URLSearchParams(useLocation().search);

    const src = queryString.get("src") || "";
    const name = queryString.get("name") || "";
    const email = queryString.get("email") || "";
    const type = queryString.get("type") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                //@ts-ignore
                `${process.env.REACT_APP_API_URL}/api/porsche/unsubscribe`,
                values
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    const readableType = () => {
        switch (type) {
            case "porsche_news_update":
                return "Porsche News Updates";
            case "porsche_news_and_events":
                return "Porsche News and Events";
            case "porsche_surveys":
                return "Porsche Surveys";
            case "new_car_launch_communications":
                return "New Car launch communications";
        }
    };

    const isCorrectType = (type: string) => {
        return (
            type === "porsche_news_update" ||
            type === "porsche_news_and_events" ||
            type === "porsche_surveys" ||
            type === "new_car_launch_communications"
        );
    }

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Unsubscribe</h1>

            {submitSuccessful ? (
                <p>Your request has been successfully submitted</p>
            ) : (
                <>
                    <p>
                        We are sorry you no longer wish to receive email
                        communications from {src !== "" ? src : "us"}.
                    </p>

                    {type &&
                    <p>
                        By unsubscribing, please be advised you will no longer receive {readableType()}
                    </p>
                    }

                    <Break />

                    <Formik
                        initialValues={{
                            type: type,
                            name: name,
                            email: email,
                            src: src,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ values, errors, touched, setFieldValue }) => {
                            console.log(values);

                            return (
                                <Form>
                                    {
                                        type && isCorrectType(type) ?
                                            <RadioContainerOuter>
                                                <RadioContainer>
                                                    <CustomRadio
                                                        onChange={(value) => {
                                                            setFieldValue(
                                                                "type",
                                                                type
                                                            );
                                                        }}
                                                        checked={
                                                            values.type === type
                                                        }
                                                        // @ts-ignore
                                                        value={
                                                            values.type
                                                        }
                                                        type="checkbox"
                                                    />
                                                    <RadioCheck
                                                        square={true}
                                                        className="checkmark"
                                                    />
                                                    Unsubscribe from {readableType()}
                                                </RadioContainer>
                                            </RadioContainerOuter>
                                            :
                                            <>
                                                <StyledLabel htmlFor="type">
                                                    What communication would you like to unsubscribe from? <Required />
                                                </StyledLabel>
                                                <StyledField
                                                    as="select"
                                                    name="type"
                                                    value={values.type}
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            "type",
                                                            value.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    <option value="porsche_news_update">Porsche News Update</option>
                                                    <option value="porsche_news_and_events">Porsche News and Events</option>
                                                    <option value="porsche_surveys">Porsche Surveys</option>
                                                    <option value="new_car_launch_communications">New Car launch communications</option>
                                                    <option value="all">All Communications</option>
                                                </StyledField>
                                                {errors.type && touched.type ? (
                                                    <Error>Type is required</Error>
                                                ) : null}
                                            </>
                                    }

                                    {type &&
                                        <RadioContainerOuter>
                                            <RadioContainer>
                                                <CustomRadio
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            "type",
                                                            values.type === "all" ? type : "all"
                                                        );
                                                    }}
                                                    checked={
                                                        values.type === "all"
                                                    }
                                                    // @ts-ignore
                                                    value={
                                                        values.type
                                                    }
                                                    type="checkbox"
                                                />
                                                <RadioCheck
                                                    square={true}
                                                    className="checkmark"
                                                />
                                                Unsubscribe from all Porsche email communications
                                            </RadioContainer>
                                        </RadioContainerOuter>
                                    }

                                    {src === "" && (
                                        <>
                                            <StyledLabel htmlFor="src">
                                                Email address unsubscribing from{" "}
                                                <Required />
                                            </StyledLabel>
                                            <StyledField id="src" name="src" />

                                            {errors.src && touched.src ? (
                                                <Error>
                                                    Email address unsubscribing
                                                    from is required
                                                </Error>
                                            ) : null}
                                        </>
                                    )}
                                    
                                    <StyledLabel htmlFor="name">
                                        Name <Required />
                                    </StyledLabel>
                                    <StyledField id="name" name="name" />

                                    {errors.name && touched.name ? (
                                        <Error>Name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField
                                        type="email"
                                        id="email"
                                        name="email"
                                    />
                                    {errors.email && touched.email ? (
                                        <Error>{errors.email}</Error>
                                    ) : null}

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default UnsubscribeForm;
