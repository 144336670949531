import React from "react";
import styled, { keyframes, css } from "styled-components";

import { Field } from "formik";

export const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
`;

export const Break = styled.div`
    height: 3px;
    background: #ddd;
    margin-bottom: 2em;
`;

export const StyledField = styled(Field)`
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;
    height: 50px;
`;

export const StyledInput = styled.input`
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;
    height: 50px;
`;

export const StyledTextarea = styled.textarea`
    font: inherit;
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;
`;

export const StyledCheckbox = styled(Field)`
    width: 50px;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;
    height: 50px;
    cursor: pointer;
`;

export const StyledLabel = styled.label`
    margin-top: 1em;
    margin-bottom: 0.5em;
    display: block;
`;

export const StyledRadioButton = styled(Field)`
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1em;
    outline: none;
    cursor: pointer;
    margin-right: 1em;
    margin-bottom: 0.25em;
`;

export const StyledRadioButtonLabel = styled.label`
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
`;

export const Error = styled.div`
    color: red;
    margin-bottom: 1em;
`;

export const SubmitSection = styled.div`
    text-align: right;
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
`;

const ArrowStylesBottom = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 45%;
    left: 15px;
    transform: translateY(-100%) rotate(55deg);
`;

const ArrowStylesTop = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(100%) rotate(-55deg);
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
    outline: none;
    padding: 1.2em 2em;
    font-weight: bold;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    background: white;
    border: 1px solid black;
    border-radius: 0;

    transition: all 0.1s;
    position: relative;

    &:hover {
        background: ${({ disabled }) => (disabled ? "#3e3e3e" : "#d5001c")};
        border: 1px solid
            ${({ disabled }) => (disabled ? "#3e3e3e" : "#d5001c")};
        color: white;
    }

    ${({ color }) =>
        color === "green" &&
        `
        background: #11a211;
        border: 1px solid #11a211;
        color: white;
    `}

    ${({ color }) =>
        color === "red" &&
        `
        background: #d5001c;
        border: 1px solid #d5001c;
        color: white;
    `}


${({ color, disabled }) =>
        (color === "grey" || disabled) &&
        `
        background: #3e3e3e;
        border: 1px solid #3e3e3e;
        color: white;
    `}

    
    ${({ hasArrow, color }) =>
        hasArrow &&
        `
        padding-left: 2.5rem;
    
        &:before {
            ${ArrowStylesBottom};
            background: ${color ? "white" : "black"};
        }
        
        &:after {
            ${ArrowStylesTop};
            background: ${color ? "white" : "black"};
        }
        
        &:hover {
            &:before {
                background: white !important;
                ${ArrowStylesBottom};
            }
            
            &:after {
                background: white !important;
                ${ArrowStylesTop};
            }
        }
    `}
`;

interface SubmitButtonProps {
    hasArrow?: boolean;
    color?: string;
    disabled?: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
    margin-left: 1em;
    border: 5px solid #e2e2e2;
    border-radius: 50%;
    border-top: 5px solid #5d5d5d;
    width: 30px;
    height: 30px;
    animation: ${rotate} 1.2s linear infinite;
`;

export const Title = styled.h1`
    font-size: 1.7em;
`;

export const Subtitle = styled.h2`
    font-size: 1.5em;
`;

export const FlexBox = styled.div`
    display: flex;
    margin-bottom: 1em;
`;

export const LogoContainer = styled.div`
    text-align: center;
    margin-bottom: 2em;
    margin-top: 1em;
`;

export const Required = () => {
    return <span style={{ color: "red" }}>*</span>;
};

export const RadioContainerOuter = styled.div`
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
`;

export const RadioCheck = styled.span<RadioCheckProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid grey;
    border-radius: ${({ square }) => (square ? "0" : "50%")};

    &:after {
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 19px;
        height: 19px;
        border-radius: ${({ square }) => (square ? "0" : "50%")};
        background: black;
    }
`;

interface RadioCheckProps {
    square?: boolean;
}

export const CustomRadio = styled.input<CustomRadioProps>`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + ${RadioCheck} {
        &:after {
            display: block !important;
        }
    }
`;

interface CustomRadioProps {
    value: boolean;
}

export const RadioContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 4px;
`;

export const Checkmark = styled.div`
    position: absolute;
    top: 25px;
    left: 85%;

    &:after {
        content: "";
        display: block;
        width: 7px;
        height: 2px;
        transform: rotate(45deg);
        background: #11a211;
    }

    &:before {
        content: "";
        display: block;
        width: 13px;
        height: 2px;
        transform: rotate(-45deg);
        background: #11a211;
        position: relative;
        top: -1px;
        left: 4px;
    }
`;
