import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import axios from "axios";

import {
  Container,
  Break,
  StyledField,
  StyledLabel,
  Error,
  SubmitSection,
  SubmitButton,
  Loader,
  Title,
  Subtitle,
  FlexBox,
  Required,
  LogoContainer,
} from "../components/FormComponents";

const Banner = styled.img`
  width: 100%;
`;

const Logo = styled.img`
  width: 300px;
`;

const Schema = Yup.object().shape({
  membership_number: Yup.string().required(),
  title: Yup.string().required(),
  first_name: Yup.string().required(),
  surname: Yup.string().required(),
  house: Yup.string().required(),
  street_address: Yup.string().required(),
  town: Yup.string().required(),
  post_code: Yup.string().required(),
  email: Yup.string().email().required(),
});

const ArtworkRequest = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleOnSubmit = (values: any) => {
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/porsche/customer-artwork-details`,
        values,
        {
          headers: {
            Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
          },
        }
      )
      .then(({ data }) => {
        setSubmitting(false);
        setSubmitSuccessful(true);
      })
      .catch(({ response: { data } }) => {
        if (data.code === 422) {
          setSubmitError(data.error);
        } else {
          setSubmitError("There was an error submitting your form.");
        }
        setSubmitting(false);
      });
  };

  return (
    <Container>
      <LogoContainer>
        <Logo src="/porscheclublogo.png" />
      </LogoContainer>
      <Banner src="/porscheclub.jpeg" />
      <Title className="title">
        Porsche Cars Great Britain and Porsche Club.
        <br /> Working together to celebrate your passion for Porsche.
      </Title>

      <Break />

      {submitSuccessful ? (
        <>
          <p>Artwork request successfully submitted</p>
        </>
      ) : (
        <>
          <p>
            Thank you for requesting your complimentary artwork print, created
            exclusively for Porsche Cars Great Britain by British illustrator
            Guy Allen. To claim your bespoke print, please enter your details in
            the form below.
          </p>
          <p>
            The personal data you provide by completing this form will be
            collected and used by us, Porsche Cars Great Britain Limited, for
            the purpose of providing your complimentary artwork print. Further
            details of how we may use your personal data is set out in our{" "}
            <a
              href="https://www.porsche.com/uk/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>

          <Formik
            initialValues={{
              membership_number: "",
              title: "",
              first_name: "",
              surname: "",
              house: "",
              street_address: "",
              town: "",
              post_code: "",
              email: "",
            }}
            validationSchema={Schema}
            onSubmit={handleOnSubmit}
          >
            {({ errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <StyledLabel htmlFor="membership_number">
                    Porsche Club GB membership number <Required />
                  </StyledLabel>
                  <StyledField
                    id="membership_number"
                    name="membership_number"
                  />
                  {errors.membership_number && touched.membership_number ? (
                    <Error>Membership number is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="title">
                    Title <Required />
                  </StyledLabel>
                  <StyledField
                    as="select"
                    name="title"
                    onChange={(value: any) =>
                      setFieldValue("title", value.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="mr">Mr</option>
                    <option value="mrs">Mrs</option>
                    <option value="miss">Miss</option>
                    <option value="ms">Ms</option>
                  </StyledField>
                  {errors.title && touched.title ? (
                    <Error>Title is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="first_name">
                    Firstname <Required />
                  </StyledLabel>
                  <StyledField id="first_name" name="first_name" />
                  {errors.first_name && touched.first_name ? (
                    <Error>Firstname is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="surname">
                    Surname <Required />
                  </StyledLabel>
                  <StyledField id="surname" name="surname" />
                  {errors.surname && touched.surname ? (
                    <Error>Surname is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="house">
                    House name/number <Required />
                  </StyledLabel>
                  <StyledField id="house" name="house" />
                  {errors.house && touched.house ? (
                    <Error>House name/number is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="street_address">
                    Street address <Required />
                  </StyledLabel>
                  <StyledField id="street_address" name="street_address" />
                  {errors.street_address && touched.street_address ? (
                    <Error>Street address is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="town">
                    Town <Required />
                  </StyledLabel>
                  <StyledField id="town" name="town" />
                  {errors.town && touched.town ? (
                    <Error>Town is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="post_code">
                    Postcode <Required />
                  </StyledLabel>
                  <StyledField id="post_code" name="post_code" />
                  {errors.post_code && touched.post_code ? (
                    <Error>Postcode is required</Error>
                  ) : null}
                  <StyledLabel htmlFor="email">
                    Email <Required />
                  </StyledLabel>
                  <StyledField id="email" name="email" />
                  {errors.email && touched.email ? (
                    <Error>Email is required and must be valid</Error>
                  ) : null}
                  {/*<Subtitle className="title">*/}
                  {/*    If you don’t currently receive*/}
                  {/*    communications from Porsche Cars Great*/}
                  {/*    Britain but want to be contacted by*/}
                  {/*    them, then please tick below.*/}
                  {/*</Subtitle>*/}
                  {/*<Subtitle className="title">*/}
                  {/*    If Porsche Cars Great Britain are*/}
                  {/*    currently in touch, you don’t need to do*/}
                  {/*    anything unless you wish to update your*/}
                  {/*    preferences by indicating any changes*/}
                  {/*    below.*/}
                  {/*</Subtitle>*/}
                  {/*<p>*/}
                  {/*    In the future I would like to be*/}
                  {/*    informed about new products, events, and*/}
                  {/*    offers from Porsche. In light of this, I*/}
                  {/*    hereby provide the following consent*/}
                  {/*    declaration.*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*    <b>Contact</b>*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*    Your main point of contact for consent,*/}
                  {/*    revocation, and enquiry is Porsche*/}
                  {/*    Customer Assistance Centre via phone*/}
                  {/*    03457 911 911, by email at{" "}*/}
                  {/*    <a href="mailto:customer.assistance@porsche.co.uk">*/}
                  {/*        customer.assistance@porsche.co.uk*/}
                  {/*    </a>{" "}*/}
                  {/*    or by post at Porsche Cars Great Britain*/}
                  {/*    Limited located at Bath Road, Reading,*/}
                  {/*    Berkshire, United Kingdom, RG31 7SE.*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*    Additional information concerning data*/}
                  {/*    processing and customisation in the area*/}
                  {/*    of the Porsche customer and prospect*/}
                  {/*    care by Porsche Cars Great Britain*/}
                  {/*    Limited can be found at{" "}*/}
                  {/*    <a*/}
                  {/*        href="www.porsche.com/uk/privacy-policy"*/}
                  {/*        target="_blank"*/}
                  {/*    >*/}
                  {/*        www.porsche.com/uk/privacy-policy*/}
                  {/*    </a>*/}
                  {/*    .*/}
                  {/*</p>*/}
                  {submitError && <Error>{submitError}</Error>}
                  <SubmitSection>
                    <SubmitButton type="submit">Submit</SubmitButton>
                    {submitting && <Loader />}
                  </SubmitSection>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default ArtworkRequest;
