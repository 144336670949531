import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Logo from "../components/Logo";

import {
    Container,
    Break,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
} from "../components/FormComponents";

const Schema = Yup.object().shape({
    src: Yup.string().required(),
    name: Yup.string().required(),
    email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),
});

const Unsubscribe = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const queryString = new URLSearchParams(useLocation().search);

    const src = queryString.get("src") || "";
    const name = queryString.get("name") || "";
    const email = queryString.get("email") || "";

    const handleOnSubmit = (values: any) => {
        setSubmitting(true);
        axios
            .post(
                //@ts-ignore
                `${process.env.REACT_APP_API_URL}/api/porsche/unsubscribe`,
                values
                // {
                //     headers: {
                //         Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                //     },
                // }
            )
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch(({ response: { data } }) => {
                if (data.code === 422) {
                    setSubmitError(data.error);
                } else {
                    setSubmitError("There was an error submitting your form.");
                }
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>

            <h1 className="title">Unsubscribe</h1>
            <Break />

            {submitSuccessful ? (
                <p>Your request has been successfully submitted</p>
            ) : (
                <>
                    <p>
                        We are sorry you no longer wish to receive email
                        communications from {src !== "" ? src : "us"}. Please
                        complete the details below, in order to unsubscribe from
                        this mailing list.
                    </p>
                    <Break />

                    <Formik
                        initialValues={{
                            name: name,
                            email: email,
                            src: src,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleOnSubmit}
                    >
                        {({ values, errors, touched, setFieldValue }) => {
                            return (
                                <Form>
                                    {src === "" && (
                                        <>
                                            <StyledLabel htmlFor="src">
                                                Email address unsubscribing from{" "}
                                                <Required />
                                            </StyledLabel>
                                            <StyledField id="src" name="src" />

                                            {errors.src && touched.src ? (
                                                <Error>
                                                    Email address unsubscribing
                                                    from is required
                                                </Error>
                                            ) : null}
                                        </>
                                    )}

                                    <StyledLabel htmlFor="name">
                                        Name <Required />
                                    </StyledLabel>
                                    <StyledField id="name" name="name" />

                                    {errors.name && touched.name ? (
                                        <Error>Name is required</Error>
                                    ) : null}

                                    <StyledLabel htmlFor="email">
                                        Email <Required />
                                    </StyledLabel>
                                    <StyledField
                                        type="email"
                                        id="email"
                                        name="email"
                                    />
                                    {errors.email && touched.email ? (
                                        <Error>{errors.email}</Error>
                                    ) : null}

                                    {submitError && (
                                        <Error>{submitError}</Error>
                                    )}
                                    <SubmitSection>
                                        <SubmitButton type="submit">
                                            Submit
                                        </SubmitButton>
                                        {submitting && <Loader />}
                                    </SubmitSection>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Container>
    );
};

export default Unsubscribe;
