import React, { useEffect, useState } from "react";
import axios from "axios";

import { Loader, StyledField } from "./FormComponents";

const CenterSelector = ({
    name = "center_name",
    onChange,
    value,
    customOnChange
}: {
    name: string;
    onChange: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void;
    value: string;
    customOnChange?: (value: string) => void
}) => {
    const [loading, setLoading] = useState(true);
    const [centers, setCenters] = useState([]);

    const getCenters = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/porsche/centres`,
                {
                    headers: {
                        Authorization: `Basic Zm9ybTouSzQqTnIoe2M4W1JZeyp+`,
                    },
                }
            )
            .then(({ data }) => {
                setLoading(false);
                setCenters(data.data.centre_names);
            })
            .catch((e) => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getCenters();
    }, []);

    return (
        <div style={{ display: "flex" }}>
            <StyledField
                value={value}
                as="select"
                name={name}
                onChange={(value: any) => {
                    onChange(name, value.target.value);
                    customOnChange && customOnChange(value);
                }}
            >
                <option value="">Select</option>
                {centers.map(center =>
                    <option
                        key={center}
                        value={center}
                    >
                        {center}
                    </option>
                )}
            </StyledField>
            {loading && <Loader />}
        </div>
    );
};

export default CenterSelector;