import React from "react";
import styled from "styled-components";

const StyledLogo = styled.img`
    margin-top: 1rem;
    max-width: 400px;
    min-width: 400px;
`;

const Logo = ({ ...rest }) => {
    return <StyledLogo alt="Porsche Logo" src="/logo.png" {...rest} />;
};

export default Logo;
